<!-- OllamaLocalList.svelte -->
<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { writable, get } from 'svelte/store';
  import { getJSONFromStorage, putJSONInStorage } from '../utils/utils.js';
  import { ollamaModels } from '../utils/stores.js';

  const filteredModels = writable([]);
  const apiStatus = writable('loading');
  const filterText = writable('');
  const aliases = writable({});
  const selectedRow = writable(null);
  const aliasInput = writable('');
  const copyMessage = writable('');
  const showMessage = writable(false);
  const aliasSuccessMessage = writable('');

  const dispatch = createEventDispatcher();

  async function loadAliases() {
    const storedAliases = await getJSONFromStorage('modelAliases');
    aliases.set(storedAliases);
    console.log('Loaded aliases from storage:', storedAliases);
  }

  async function saveAliases(value) {
    await putJSONInStorage('modelAliases', value);
    console.log('Saved aliases to storage:', value);
  }

  aliases.subscribe(value => {
    if (get(selectedRow) !== null) {
      saveAliases(value);
    }
  });

  function toggleRowSelection(modelName) {
    if (get(selectedRow) === modelName) {
      selectedRow.set(null);
      aliasInput.set('');
    } else {
      selectedRow.set(modelName);
      const currentAliases = get(aliases);
      aliasInput.set(Object.keys(currentAliases).find(key => currentAliases[key] === `ollama/${modelName}`) || '');
    }
  }

  function applyFilter() {
    const currentFilter = get(filterText).toLowerCase();
    const modelList = get(ollamaModels);

    console.log("Applying filter:", currentFilter);
    console.log("Models list before filtering:", modelList);

    const filtered = modelList.filter(model => model.name.toLowerCase().includes(currentFilter));
    filteredModels.set(filtered);

    console.log("Filtered models:", filtered);
  }

  function addAliasForModel(modelName) {
    const fullModelName = `ollama/${modelName}`;
    aliases.update(current => {
      const existingAliasKey = Object.keys(current).find(key => current[key] === fullModelName);
      if (existingAliasKey && existingAliasKey !== get(aliasInput)) {
        delete current[existingAliasKey];
      }

      if (get(aliasInput)) {
        current[get(aliasInput)] = fullModelName;
      }

      return current;
    });

    aliasSuccessMessage.set(`Alias "${get(aliasInput)}" saved for model "${modelName}".`);

    // Clear the success message after 3 seconds
    setTimeout(() => {
      aliasSuccessMessage.set('');
    }, 3000);
  }

  // Subscribe to changes in filterText and models
  filterText.subscribe(() => {
    applyFilter();
  });

  ollamaModels.subscribe(() => {
    applyFilter();
  });

  onMount(() => {
    fetchOllamaModels(); // Call the function to fetch models
    loadAliases();
  });

  async function copyToClipboard(name) {
    const textToCopy = "ollama/" + name;

    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('Text copied successfully using Clipboard API');
      showCopyMessage('Copied to Clipboard.');
    } catch (err) {
      console.error('Failed to copy using Clipboard API: ', err);

      // Fallback to using execCommand for environments where Clipboard API is not permitted
      try {
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textarea);

        if (successful) {
          console.log('Text copied successfully using execCommand');
          showCopyMessage('Copied to Clipboard.');
        } else {
          console.error('Failed to copy using execCommand');
          showCopyMessage('Failed to copy text.');
        }
      } catch (err) {
        console.error('Fallback failed, could not copy text: ', err);
        showCopyMessage('Failed to copy text.');
      }
    }
  }

  function showCopyMessage(message) {
    copyMessage.set(message);
    showMessage.set(true);
    setTimeout(() => {
      showMessage.set(false);
    }, 3000); // Hide the message after 3 seconds
  }

  async function deleteModel(name) {
    try {
      const response = await fetch('http://localhost:11434/api/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
      });

      if (!response.ok) throw new Error('Failed to delete');

      apiStatus.set({ status: 'success', message: `${name} deleted successfully.` });
      fetchOllamaModels(); // Call the function in stores.js to refetch models
    } catch (error) {
      console.error('Failed to delete: ', error);
      apiStatus.set({ status: 'error', message: 'Error deleting model.' });
    }
  }

  async function fetchOllamaModels() {
    apiStatus.set('loading');
    try {
      const response = await fetch('http://localhost:11434/api/tags');
      if (!response.ok) throw new Error('Failed to fetch');

      const data = await response.json();
      ollamaModels.set(data.models);

      if (data.models.length > 0) {
        apiStatus.set('success');
      } else {
        apiStatus.set('noModels');
      }

      applyFilter(); // Apply initial filter
    } catch (error) {
      console.error('API call failed: ', error);
      apiStatus.set('error');
      ollamaModels.set([]);
    }

    dispatch('refreshed');
  }
</script>

<style>
  tr:hover { background-color: #f5f5f5; }
  .alias-container {
    display: flex;
    align-items: center;
  }
  .alias-container .input {
    margin-right: 0.5rem;
    flex: 0 1 auto;
    max-width: 200px;
  }
</style>

<article class="message is-info">
  <div class="message-header columns is-vcentered">
    <div class="column is-narrow">
      <p>Downloaded Ollama Models</p>
    </div>
    <div class="column">
      <input class="input is-small" type="text" placeholder="Filter models by name" bind:value={$filterText}>
    </div>
    <div class="column is-narrow has-text-right" style="flex: 0 1 auto;">
      <button class="button is-white" on:click={fetchOllamaModels} title="Refresh Models">
        <span class="icon is-small">
          <i class="fas fa-sync-alt"></i>
        </span>
      </button>
    </div>
  </div>
  <div class="message-body">
    {#if $apiStatus === 'loading'}
      <p>Loading models...</p>
    {:else if $apiStatus === 'error'}
      <p class="has-text-danger">Please make sure Ollama is running (see setup above).</p>
    {:else if $apiStatus === 'noModels'}
      <p class="has-text-success">Ollama running, download models below.</p>
    {:else if $apiStatus === 'success'}
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Name</th>
            <th>Parameter Size</th>
            <th>Quantization Level</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {#each $filteredModels as { name, details: { parameter_size, quantization_level } }}
            <tr>
              <td on:click={() => toggleRowSelection(name)}>
                <span class="icon">
                  {#if $selectedRow === name}
                    <i class="fas fa-chevron-down"></i>
                  {:else}
                    <i class="fas fa-chevron-right"></i>
                  {/if}
                </span>
              </td>
              <td>
                <button on:click={() => copyToClipboard(name)} class="button is-small is-info">Copy</button>
              </td>
              <td>ollama/{name}</td>
              <td>{parameter_size}</td>
              <td>{quantization_level}</td>
              <td><button on:click={() => deleteModel(name)} class="button is-small is-danger">Delete</button></td>
            </tr>
            {#if $selectedRow === name}
              <tr>
                <td colspan="6">
                  <div class="alias-container">
                    <input class="input" type="text" bind:value={$aliasInput} placeholder="Enter alias" />
                    <button class="button is-info" on:click={() => addAliasForModel(name)}>Save Alias</button>
                  </div>
                </td>
              </tr>
            {/if}
          {/each}
        </tbody>
      </table>
    {/if}
  </div>
</article>

{#if $showMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {$copyMessage}
</div>
{/if}

{#if $aliasSuccessMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {$aliasSuccessMessage}
</div>
{/if}
