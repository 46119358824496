<script>
    import { writable } from 'svelte/store';
    import { createEventDispatcher } from 'svelte';
  
    export let selectedModelTag = '';
  
    const dispatch = createEventDispatcher();
    const progress = writable(0);
    let isDownloading = false;
    let downloadFailed = false;
    let totalSize = 0;
    let completedSize = 0;
  
    async function downloadModel() {
      if (!selectedModelTag) return;
  
      isDownloading = true;
      progress.set(0); // Reset progress on new download
  
      try {
        const response = await fetch('http://localhost:11434/api/pull', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: selectedModelTag, stream: true }),
        });
  
        if (!response.body || !response.ok) throw new Error('Network response was not ok.');
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
  
        let responseBody = '';
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          responseBody += decoder.decode(value, { stream: true });
          const lines = responseBody.split('\n').filter(Boolean);
          for (let i = 0; i < lines.length; i++) {
            try {
              const json = JSON.parse(lines[i]);
              if (json.total) {
                totalSize = json.total;
              }
              if (json.completed) {
                completedSize = json.completed;
                const currentProgress = (completedSize / totalSize) * 100;
                progress.set(currentProgress);
              }
              if (json.status === 'success') {
                console.log('Download complete');
                isDownloading = false;
                dispatch('downloadComplete');
                break;
              }
            } catch (e) {
              // handle parsing error
            }
          }
        }
      } catch (error) {
        console.error('Failed to download model:', error);
        isDownloading = false;
        isDownloading = false;
        downloadFailed = true; // Set the flag to true on download failure
      }
    }
  </script>
  
  <button class="button is-info" on:click={downloadModel} disabled={isDownloading || !selectedModelTag}>
    {isDownloading ? 'Downloading...' : 'Download'}
  </button>
  
  {#if isDownloading}
    <progress class="progress is-small is-info" value={$progress} max="100">{$progress}%</progress>
  {/if}

  {#if downloadFailed}
    <p class="has-text-danger">Download failed. Please make sure Ollama is installed and running and try again (see setup instructions above).</p>
  {/if}
  