<script>
    // Script section if needed
  </script>
  
  <style>
    .iframe-container {
      display: flex;    /* Use flexbox to provide a flexible container */
      justify-content: center; /* Center iframe horizontally */
      align-items: start; /* Center iframe vertically */
      height: 60vh;    /* Viewport height for full size */
      width: 100vw;     /* Viewport width for full size */
      overflow: hidden; /* Hide any overflow */
    }
  
    iframe {
      width: 100%;    /* Full width of the container */
      height: 100%;   /* Full height of the container */
      border: none;   /* Remove border */
    }
  </style>
  
  <article class="message is-info">
    <div class="message-header">
      <p>Share Feedback</p>
    </div>
    <div class="message-body iframe-container">
      <iframe src="https://sheetprompter.productlift.dev/add_feedback_widget" title="Feedback Widget" frameborder="0"/>
    </div>
  </article>
  