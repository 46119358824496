<!-- OpenRouter.svelte -->
<script>
  import OpenRouterSettings from './OpenRouterSettings.svelte';
  import { onMount, tick } from 'svelte';
  import { writable, get } from 'svelte/store';
  import { getJSONFromStorage, putJSONInStorage } from '../utils/utils.js';
  import { openrouterModels } from '../utils/stores.js';

  const modelAliasesKey = 'modelAliases';

  const aliases = writable({});
  let filteredModels = [];
  let filterText = '';
  let copyMessage = '';
  let showMessage = false;
  let selectedRow = null;
  let aliasInput = '';
  let aliasSuccessMessage = writable('');

  async function loadAliases() {
    const storedAliases = await getJSONFromStorage(modelAliasesKey);
    aliases.set(storedAliases);
    console.log('Loaded aliases from storage:', storedAliases);
  }

  async function saveAliases(value) {
    await putJSONInStorage(modelAliasesKey, value);
    console.log('Saved aliases to storage:', value);
  }

  onMount(() => {
    loadAliases();
  });

  aliases.subscribe(value => {
    if (selectedRow !== null) {
      saveAliases(value);
    }
  });

  function toggleRowSelection(modelId) {
    if (selectedRow === modelId) {
      selectedRow = null;
      aliasInput = '';
    } else {
      selectedRow = modelId;
      const currentAliases = get(aliases);
      aliasInput = Object.keys(currentAliases).find(key => currentAliases[key] === `openrouter/${modelId}`) || '';
    }
  }

  function extractSize(id) {
    const regex = /(\d+x)?(\d+b)/i;
    const parts = id.split('/');
    const lastSegment = parts.pop();
    const match = lastSegment.match(regex);
    return match ? (match[1] ? match[1] + match[2] : match[2]) : 'N/A';
  }

  async function copyToClipboard(id, event) {
    event.stopPropagation();
    const textToCopy = "openrouter/" + id;

    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('Text copied successfully using Clipboard API');
      showCopyMessage('Copied to Clipboard.');
    } catch (err) {
      console.error('Failed to copy using Clipboard API: ', err);

      try {
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textarea);

        if (successful) {
          console.log('Text copied successfully using execCommand');
          showCopyMessage('Copied to Clipboard.');
        } else {
          console.error('Failed to copy using execCommand');
          showCopyMessage('Failed to copy text.');
        }
      } catch (err) {
        console.error('Fallback failed, could not copy text: ', err);
        showCopyMessage('Failed to copy text.');
      }
    }
  }

  function showCopyMessage(message) {
    copyMessage = message;
    showMessage = true;
    setTimeout(() => {
      showMessage = false;
    }, 3000);
  }

  function addAliasForModel(modelId) {
    const fullModelId = `openrouter/${modelId}`;
    aliases.update(current => {
      const existingAliasKey = Object.keys(current).find(key => current[key] === fullModelId);
      if (existingAliasKey && existingAliasKey !== aliasInput) {
        delete current[existingAliasKey];
      }

      if (aliasInput) {
        current[aliasInput] = fullModelId;
      }

      return current;
    });

    aliasSuccessMessage.set(`Alias "${aliasInput}" saved for model "${modelId}".`);

    setTimeout(() => {
      aliasSuccessMessage.set('');
    }, 3000);
  }

  function applyFilter() {
    filteredModels = get(openrouterModels).filter(model => model.id.toLowerCase().includes(filterText.toLowerCase()) ||
                                            model.name.toLowerCase().includes(filterText.toLowerCase()));
  }

  async function copyToCell(text) {
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.values = [[text]];
      await context.sync();
    });
  }

  $: filterText, applyFilter();
</script>

<style>
  tr:hover { background-color: #f5f5f5; }
  tr.selectedRow { background-color: #e9ecef; }
  .alias-container {
    display: flex;
    align-items: center;
  }
  .alias-container .input {
    margin-right: 0.5rem;
    flex: 0 1 auto;
    max-width: 200px;
  }
</style>

<article class="message is-info">
  <div class="message-header">
    <p>OpenRouter Setup</p>
  </div>
  <div class="message-body">
    <p><a href="https://openrouter.ai" target="_blank">OpenRouter</a> is a unified interface for LLMs which helps you find the best models and prices. Some models are even free.</p>
    <br/>To use OpenRouter, you'll need to create an account and then create <a target="_blank" href="https://openrouter.ai/keys">an API key</a> and then enter it below:
    <br/><br/><OpenRouterSettings />
  </div>
</article>
<article class="message is-info">
  <div class="message-header">
    <p>Using OpenRouter</p>
  </div>
  <div class="message-body">
    <p><strong>=LM(MODEL_ID, PROMPT)</strong></p>
    <br/>Example:
    <p><strong>=LM("openrouter/google/gemma-7b-it:free", "tell me a joke")</strong><br/><br/><button class="button is-info is-small" on:click={() => copyToCell('=LM("openrouter/google/gemma-7b-it:free", "tell me a joke")')}>Copy to Cell</button></p>
  </div>
</article>
<article class="message is-info">
  <div class="message-header columns">
    <div class="column is-one-third">
      <p>OpenRouter Models</p>
    </div>
    <div class="column is-two-thirds">
      <input class="input is-small" type="text" placeholder="Filter models by ID or name" bind:value={filterText}>
    </div>
  </div>
  <div class="table-container message-body">
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead class="info">
        <tr>
          <th></th>
          <th></th>
          <th>ID</th>
          <th>Name</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody class="info-light">
        {#each filteredModels as model}
          <tr on:click={() => toggleRowSelection(model.id)} class:selectedRow={selectedRow === model.id}>
            <td>
              <span class="icon">
                {#if selectedRow === model.id}
                  <i class="fas fa-chevron-down"></i>
                {:else}
                  <i class="fas fa-chevron-right"></i>
                {/if}
              </span>
            </td>
            <td>
              <button on:click={(event) => copyToClipboard(model.id, event)} class="button is-small is-info">Copy</button>
            </td>
            <td>openrouter/{model.id}</td>
            <td>{model.name}</td>
            <td>{model.size}</td>
          </tr>
          {#if selectedRow === model.id}
            <tr>
              <td colspan="5">
                <div>
                  <p><strong>Description:</strong> {model.description}</p>
                  <p><strong>Context length:</strong> {model.context_length}</p>
                  <p><strong>Price per input token:</strong> {model.pricing.prompt} <strong>Price per output token:</strong> {model.pricing.completion}</p>
                  <div class="field alias-container">
                    <input class="input" id="alias-{model.id}" type="text" bind:value={aliasInput} placeholder="Enter alias" />
                    <button class="button is-info" on:click={() => addAliasForModel(model.id)}>Save Alias</button>
                  </div>
                </div>
              </td>
            </tr>
          {/if}
        {/each}
      </tbody>
    </table>
  </div>
</article>
{#if showMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {copyMessage}
</div>
{/if}
{#if $aliasSuccessMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {$aliasSuccessMessage}
</div>
{/if}
