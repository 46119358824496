<script>
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { getFromStorage, putInStorage } from '../utils/utils.js';

  // Initialize writable stores for reactive variables
  let openRouterKey = '';
  let showSuccessMessage = writable(false);

  // Function to load settings from storage
  async function loadSettings() {
    openRouterKey = await getFromStorage('openRouterKey') || '';
  }

  // Function to save settings to storage
  async function saveSettings() {
    await putInStorage('openRouterKey', openRouterKey);

    // Show success message
    showSuccessMessage.set(true);
    // Hide success message after 3 seconds
    setTimeout(() => showSuccessMessage.set(false), 3000);
  }

  // Load settings on component mount
  onMount(() => {
    loadSettings();
  });
</script>

<div class="box">
  <form on:submit|preventDefault={saveSettings}>
    <div class="field has-addons">
      <div class="control">
        <div class="button is-static">API Key</div>
      </div>
      <p class="control is-expanded">
        <input class="input" type="text" placeholder="API key" bind:value={openRouterKey}>
      </p>
    </div>
    <!-- Success Message -->
    {#if $showSuccessMessage}
      <div class="notification is-success is-light">
        Settings saved successfully!
      </div>
    {/if}
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-info">Save</button>
      </div>
    </div>
  </form>
</div>
