// src/utils/utils.js

const dbName = "StorageDB";
const storeName = "keyValueStore";

function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = event => {
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: "key" });
    };

    request.onsuccess = event => {
      resolve(event.target.result);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

async function getTransaction(storeName, mode) {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, mode);
  return transaction.objectStore(storeName);
}

export async function getFromStorage(key) {
  const store = await getTransaction(storeName, "readonly");
  return new Promise((resolve, reject) => {
    const request = store.get(key);

    request.onsuccess = event => {
      resolve(event.target.result ? event.target.result.value : null);
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

export async function getJSONFromStorage(key) {
  const result = await getFromStorage(key);
  return result ? JSON.parse(result) : {};
}

export async function putInStorage(key, value) {
  const store = await getTransaction(storeName, "readwrite");
  return new Promise((resolve, reject) => {
    const request = store.put({ key, value });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = event => {
      reject(event.target.error);
    };
  });
}

export async function putJSONInStorage(key, value) {
  return await putInStorage(key, JSON.stringify(value));
}

// Add more utility functions as needed