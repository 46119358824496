<!-- ModelList.svelte -->
<script>
  import Autocomplete from './Autocomplete.svelte';
  import { writable } from 'svelte/store';

  export let modelList = [];

  const internalModelList = writable(modelList);

  // Sync internalModelList with modelList prop
  $: if ($internalModelList !== modelList) {
    internalModelList.set(modelList);
  }

  // Sync modelList prop with internalModelList
  $: modelList = $internalModelList;

  let newModel = '';

  function addModel() {
    if (newModel && !$internalModelList.includes(newModel)) {
      internalModelList.update(list => [...list, newModel]);
      newModel = '';
    }
  }

  function removeModel(index) {
    internalModelList.update(list => {
      list.splice(index, 1);
      return list;
    });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      addModel();
    }
  }

  function stripPrefix(modelName) {
    return modelName.split('/').pop();
  }
</script>

<style>
  .input-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .model-item {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
  }
  .model-item button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  .tooltip {
    display: none;
    position: absolute;
    top: -1.5rem;
    left: 0;
    background-color: #333;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
  }
  .model-item:hover .tooltip {
    display: block;
  }
</style>

<div class="label">Models</div>
<div class="input-container">
  {#each $internalModelList as model, index}
    <div class="model-item" data-tooltip={model}>
      <span>{stripPrefix(model)}</span>
      <button on:click={() => removeModel(index)}>x</button>
      <div class="tooltip">{model}</div>
    </div>
  {/each}
  <Autocomplete bind:selectedModel={newModel} placeholder="Add a model..." />
</div>

<button class="button is-info" on:click={addModel} disabled={!newModel}>Add Model</button>