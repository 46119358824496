<!-- PromptEditor.svelte -->
<script>
  import { createEventDispatcher, onMount } from 'svelte';
  export let value = ""; // Bindable property for the template value

  let nextIndex = 0;
  let textareaRef;
  const dispatch = createEventDispatcher();
  let columnHeaders = [];
  let columnMap = {}; // Mapping of column headers to column names (A, B, C, ...)

  let variableSettings = [];

  const insertVariablePlaceholder = () => {
      const cursorPos = textareaRef.selectionStart;
      const textBefore = value.substring(0, cursorPos);
      const textAfter = value.substring(cursorPos);
      const placeholder = `{${nextIndex}}`;
      value = `${textBefore}${placeholder}${textAfter}`;

      // Set the initial column to the first item in columnHeaders if it exists
      const initialColumn = columnHeaders.length > 0 ? columnMap[columnHeaders[0]] : null;

      // Create a new array for reactivity
      variableSettings = [...variableSettings, { index: nextIndex, column: initialColumn }];
      console.log('Variable Settings after insert:', variableSettings); // Debug log
      nextIndex++;

      // Dispatch the updated value to the parent component
      dispatch('input', value);
      dispatch('variableSettingsChange', variableSettings);

      // Update the cursor position to be after the new placeholder
      const newCursorPos = cursorPos + placeholder.length;
      setTimeout(() => {
          textareaRef.focus();
          textareaRef.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
  };

  const getColumnHeaders = async () => {
      try {
          await Excel.run(async (context) => {
              const sheet = context.workbook.worksheets.getActiveWorksheet();
              const range = sheet.getRange("A1:Z1").load("values");
              await context.sync();

              const headers = range.values[0];
              columnHeaders = [];
              columnMap = {};

              headers.forEach((header, index) => {
                  if (header) {
                      const columnLetter = String.fromCharCode(65 + index);
                      columnHeaders.push(header);
                      columnMap[header] = columnLetter;
                  }
              });

              if (columnHeaders.length === 0) {
                  columnHeaders = ["no headers in sheet"];
              }

              console.log('Column headers:', columnHeaders); // Debug log
              console.log('Column map:', columnMap); // Debug log
          });

          // Set default variable settings after fetching headers
          if (columnHeaders.length > 0) {
              const initialColumn = columnMap[columnHeaders[0]];
              variableSettings = variableSettings.map((setting) => ({
                  ...setting,
                  column: initialColumn,
              }));
              dispatch('variableSettingsChange', variableSettings);
          }
      } catch (error) {
          console.error("Error fetching column headers: ", error);
          columnHeaders = ["Error fetching headers"];
      }
  };

  onMount(() => {
      textareaRef.focus();
      getColumnHeaders();
  });

  const handleColumnChange = (index, event) => {
      // Create a new array for reactivity
      variableSettings = variableSettings.map((setting, i) => 
          i === index ? { ...setting, column: columnMap[event.target.value] } : setting
      );
      console.log('Variable Settings after change:', variableSettings); // Debug log
      dispatch('variableSettingsChange', variableSettings);
  };
</script>

<div class="container">
  <div class="field">
      <label class="label" for="promptTemplate">Prompt Template</label>
      <div class="control">
          <textarea
              id="promptTemplate"
              class="textarea"
              bind:value={value}
              bind:this={textareaRef}
              rows="3"
              placeholder="Enter your prompt template here..."></textarea>
      </div>
  </div>
  <div class="field is-grouped">
      {#each variableSettings as { index }, i}
          <div class="control">
              <label class="label" for={`var-select-${index}`}>{`Var ${index}:`}</label>
              <div class="select">
                  <select id={`var-select-${index}`} on:change={event => handleColumnChange(i, event)}>
                      {#each columnHeaders as header}
                          <option value={header}>{header}</option>
                      {/each}
                  </select>
              </div>
          </div>
      {/each}
      <div class="control">
          <button class="button is-info" on:click={insertVariablePlaceholder}>Insert Variable Placeholder</button>
      </div>
  </div>
</div>

<style>
  .container {
      max-width: 800px;
      margin: auto;
  }
  .field {
      margin-bottom: 1rem;
  }
</style>
