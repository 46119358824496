<script>
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';
    import Settings from './Settings.svelte';
  import AliasManager from './AliasManager.svelte';
  import Help from './Help.svelte';
  import CellValue from './CellValue.svelte';
  import Snapshot from './Snapshot.svelte';
  import LicenseOverlay from './LicenseOverlay.svelte';
  import Providers from './Providers.svelte';
  import Wizard from './Wizard.svelte';

  let activeTab = writable('tab7'); // This stores the current active tab
  let isLoading = writable(true); // True at start to show loading

  onMount(() => {
      // Simulate an async task, such as fetching data. Replace or remove the timeout as needed.
      setTimeout(() => {
          isLoading.set(false); // Hide loading animation when done
      }, 1000); // Adjust timeout duration based on your needs
  });
</script>
<style>
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust based on your layout */
}
</style>

{#if $isLoading}
<div class="loading-spinner">
<!-- Use Font Awesome spinner class -->
<i class="fas fa-spinner fa-spin" style="font-size: 3rem;"></i>
</div>
{:else}
<!-- <LicenseOverlay />-->
<!-- Tab Navigation -->
<div class="tabs">
<ul>
  <li class:is-active={$activeTab === 'tab2'}>
    <a id="t2" role="button" tabindex="0" on:click={() => activeTab.set('tab2')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab2')}>Cell Viewer</a>
  </li>
  <li class:is-active={$activeTab === 'tab3'}>
    <a id="t3" role="button" tabindex="0" on:click={() => activeTab.set('tab3')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab3')}>Providers</a>
  </li>
  <li class:is-active={$activeTab === 'tab5'}>
    <a id="t5" role="button" tabindex="0" on:click={() => activeTab.set('tab5')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab5')}>Snapshot</a>
  </li>
  <li class:is-active={$activeTab === 'tab8'}>
    <a id="t8" role="button" tabindex="0" on:click={() => activeTab.set('tab8')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab8')}>Aliases</a>
  </li>
  <li class:is-active={$activeTab === 'tab9'}>
    <a id="t9" role="button" tabindex="0" on:click={() => activeTab.set('tab9')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab9')}>Wizard</a>
  </li>
  <li class:is-active={$activeTab === 'tab7'}>
    <a id="t7" role="button" tabindex="0" on:click={() => activeTab.set('tab7')} on:keydown={(event) => event.key === 'Enter' && activeTab.set('tab7')}>Help</a>
  </li>
</ul>
</div>

<!-- Tab Content -->
<div>
{#if $activeTab === 'tab2'}
  <CellValue />
{:else if $activeTab === 'tab3'}
  <Providers />
{:else if $activeTab === 'tab5'}
  <Snapshot />
{:else if $activeTab === 'tab7'}
  <Help />
{:else if $activeTab === 'tab8'}
  <AliasManager />
  {:else if $activeTab === 'tab9'}
  <Wizard />
{/if}
</div>
{/if}
