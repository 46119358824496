<script>
    import { writable } from 'svelte/store';
    import GettingStarted from './GettingStarted.svelte';
    import Feedback from './Feedback.svelte';
    import Functions from './Functions.svelte';

    let activeProviderTab = writable('getting_started');
</script>

<div class="tabs">
  <ul>
    <li class:is-active={$activeProviderTab === 'getting_started'}>
      <a id="getting_started" role="button" tabindex="0" on:click={() => activeProviderTab.set('getting_started')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('getting_started')}>Getting Started</a>
    </li>
    <li class:is-active={$activeProviderTab === 'functions'}>
      <a id="functions" role="button" tabindex="0" on:click={() => activeProviderTab.set('functions')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('functions')}>Functions</a>
    </li>
    <li class:is-active={$activeProviderTab === 'feedback'}>
        <a id="feedback" role="button" tabindex="0" on:click={() => activeProviderTab.set('feedback')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('feedback')}>Feedback</a>
    </li>
  </ul>
</div>

<div>
  {#if $activeProviderTab === 'getting_started'}
    <GettingStarted />
  {:else if $activeProviderTab === 'feedback'}
    <Feedback />
  {:else if $activeProviderTab === 'functions'}
    <Functions />
  {/if}
</div>
