<script>
    import { createEventDispatcher, onMount } from 'svelte';
        
    const dispatch = createEventDispatcher();
  
    export let modelName = ''; // MODEL_NAME passed as a prop
    let modelOptions = [];
    let selectedModelTag = '';
    let selectedDisplayText = 'Select Tag'; // Initial button text
  
    async function fetchData() {
      console.log('fetchData called.');
      const url = `https://sheetprompter.s3.amazonaws.com/ollama/${modelName}.json`;
      console.log(url);
      try {
        const response = await fetch(url);
        const jsonData = await response.json();
        const data = jsonData.data;
        console.log(data);
        modelOptions = data.map(option => {
          // Split details and safely handle cases where '•' is not present
          const detailsParts = option.details.split('•');
          const detailDescription = detailsParts.length > 1 ? detailsParts[1].trim() : '?';
          
          return {
            tag: option.tag,
            displayText: `${option.tag} (${detailDescription})`,
          };
        });
      } catch (error) {
        console.error('Error fetching model data:', error);
      }
    }
    fetchData();
  
    function handleSelectionChange(value) {
      selectedModelTag = modelName + ":" + value;
      // Update the display text based on the selected tag
      const selectedOption = modelOptions.find(option => option.tag === value);
      selectedDisplayText = selectedOption ? (modelName + ":" + selectedOption.displayText) : 'Select Tag';
      console.log(selectedModelTag);
      dispatch('selectionchange', { selectedModelTag }); // Dispatching the custom event
    }
  </script>  
  
  <div class="dropdown is-hoverable">
    <div class="dropdown-trigger">
      <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" onclick={fetchData}>
        <span>{selectedDisplayText}</span> <!-- Dynamically display the selected tag or default text -->
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        {#each modelOptions as option}
          <button class="button is-white dropdown-item" on:click={() => handleSelectionChange(option.tag)}>
            {option.displayText}
          </button>
        {/each}
      </div>
    </div>
  </div>
  
  <style>
    /* Additional styles if needed to make buttons look like links */
    .dropdown-item {
      width: 100%; /* Ensure button fills the container */
      justify-content: start; /* Align text to start */
      text-align: left; /* Ensure text is left-aligned */
    }
  </style>
  