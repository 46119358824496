<script>
  import { onMount } from 'svelte';
  import OllamaModelTags from './OllamaModelTags.svelte';
  import OllamaLocalList from './OllamaLocalList.svelte';
  import DownloadComponent from './DownloadComponent.svelte';

  let models = [];
  let filteredModels = [];
  let currentSelectedTag = '';
  let key = 0;
  let selectedRow = null;
  let filterText = ''; // Reactive variable for filtering models by name

  onMount(async () => {
    const response = await fetch('https://sheetprompter.s3.amazonaws.com/ollama/ollama.json');
    const data = await response.json();
    models = data.data.map(model => ({
      ...model,
      expanded: false
    }));
    filteredModels = models;
  });

  // Update filteredModels whenever filterText changes
  $: if (filterText) {
    filteredModels = models.filter(model => model.name.toLowerCase().includes(filterText.toLowerCase()));
  } else {
    filteredModels = models;
  }

  function handleDownloadComplete() {
    key++;
  }

  function toggleRowSelection(modelId) {
    selectedRow = selectedRow === modelId ? null : modelId;
  }
  
  async function copyToCell(text) {
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.values = [[text]];
      await context.sync();
    });
  }
</script>

<style>
  tr:hover { background-color: #f5f5f5; }
  tr.selectedRow { background-color: #e9ecef; }
</style>

<article class="message is-info">
  <div class="message-header">
    <p>Ollama Setup</p>
  </div>
  <div class="message-body">
    <p><a href="https://ollama.com/" target="_blank">Ollama</a> is the easiest way to run open source LLMs locally on your computer.
    <br/>* To use Ollama, first you'll need to <a href="https://ollama.com/" target="_blank">install</a> it on your computer. 
    <br/>* Then you'll need to set an environment variable on your computer to allow this addin to communicate with Ollama:
    <br/>* Set: OLLAMA_ORIGINS=https://addin.sheetprompter.com
    <br/>* Restart Ollama (or restart your computer)
    <br/>* Now you can easily DOWNLOAD and use any of the available models (see below)</p>
  </div>
</article>
<article class="message is-info">
  <div class="message-header">
    <p>Using Ollama</p>
  </div>
  <div class="message-body">
    <p><strong> =LM(MODEL_NAME, PROMPT)</strong></p>
    <br/>Example
    <p><strong>=LM("ollama/llama2:latest", "tell me a joke")</strong><br/><br/><button class="button is-info is-small" on:click={() => copyToCell('=LM("ollama/llama2:latest", "tell me a joke")')}>Copy to Cell</button></p>
  </div>
</article>

{#key key}
  <OllamaLocalList on:refreshed={() => console.log('Models list refreshed')}/>
{/key}

<article class="message is-info">
  <div class="message-header columns">
    <div class="column is-half">
      <p>Available Ollama Models</p>
    </div>
    <div class="column is-half">
      <input class="input is-small" type="text" placeholder="Filter models by name" bind:value={filterText}>
    </div>
  </div>
  <div class="message-body">
    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {#each filteredModels as model}
          <tr on:click={() => toggleRowSelection(model.name)} class:selectedRow={selectedRow === model.name}>
            <td>
              <span class="icon">
                {#if selectedRow === model.name}
                  <i class="fas fa-chevron-down"></i>
                {:else}
                  <i class="fas fa-chevron-right"></i>
                {/if}
              </span>
            </td>
            <td>{model.name}</td>
            <td>{model.description}</td>
          </tr>
          {#if selectedRow === model.name}
            <tr>
              <td colspan="3">
                <OllamaModelTags modelName={model.name} on:selectionchange="{e => currentSelectedTag = e.detail.selectedModelTag}" />
                <DownloadComponent selectedModelTag={currentSelectedTag} on:downloadComplete={handleDownloadComplete}/>
              </td>
            </tr>
          {/if}
        {/each}
      </tbody>
    </table>
  </div>
</article>
