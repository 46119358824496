<!-- Autocomplete.svelte -->
<script>
  import { onMount } from 'svelte';
  import { combinedList } from '../utils/stores.js';
  import { writable } from 'svelte/store';

  export let selectedModel = '';
  export let placeholder = 'Search models...';

  const query = writable(selectedModel);
  const suggestions = writable([]);
  const selectedIndex = writable(-1);

  let inputElement;

  // Sync query with selectedModel
  $: if ($query !== selectedModel) {
    query.set(selectedModel);
  }

  // Sync selectedModel with query
  $: selectedModel = $query;

  // Filter the combined list based on the query
  query.subscribe(value => {
    combinedList.subscribe(list => {
      if (value) {
        const filtered = list.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
        suggestions.set(filtered);
      } else {
        suggestions.set([]);
      }
      selectedIndex.set(-1); // Reset the selection index
    });
  });

  // Handle input change
  function handleInput(event) {
    query.set(event.target.value);
  }

  // Handle suggestion click
  function selectSuggestion(suggestion) {
    query.set(suggestion.name);
    suggestions.set([]);
    inputElement.focus();
  }

  // Handle input blur event
  function handleBlur() {
    setTimeout(() => {
      suggestions.set([]);
    }, 100); // Delay to allow selection click to register
  }

  // Handle keydown events for keyboard navigation
  function handleKeydown(event) {
    if (event.key === 'ArrowDown') {
      selectedIndex.update(n => (n + 1) % $suggestions.length);
    } else if (event.key === 'ArrowUp') {
      selectedIndex.update(n => (n - 1 + $suggestions.length) % $suggestions.length);
    } else if (event.key === 'Enter' && $selectedIndex >= 0) {
      selectSuggestion($suggestions[$selectedIndex]);
    } else if (event.key === 'Escape') {
      suggestions.set([]);
    }
  }

  // Handle suggestion keydown events for selection with Enter key
  function handleSuggestionKeydown(event, suggestion) {
    if (event.key === 'Enter') {
      selectSuggestion(suggestion);
    }
  }
</script>

<style>
  .autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .suggestions {
    border: 1px solid #ddd;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 1000;
  }
  .suggestion {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  .suggestion:hover,
  .suggestion[aria-selected="true"] {
    background-color: #f5f5f5;
  }
  .suggestion:last-child {
    border-bottom: none;
  }
</style>

<div class="autocomplete">
  <input
    type="text"
    bind:this={inputElement}
    bind:value={$query}
    on:input={handleInput}
    on:keydown={handleKeydown}
    on:blur={handleBlur}
    placeholder={placeholder}
    aria-autocomplete="list"
    aria-controls="suggestions-list"
    aria-activedescendant={$selectedIndex >= 0 ? `suggestion-${$selectedIndex}` : null}
    class="input is-small"
    style="height: 2.5rem;"
  />
  {#if $suggestions.length > 0}
    <div class="suggestions" id="suggestions-list" role="listbox">
      {#each $suggestions as suggestion, index}
        <button
          class="suggestion"
          on:click={() => selectSuggestion(suggestion)}
          on:keydown={(event) => handleSuggestionKeydown(event, suggestion)}
          role="option"
          tabindex="-1"
          id={`suggestion-${index}`}
          aria-selected={$selectedIndex === index}
        >
          {suggestion.name}
        </button>
      {/each}
    </div>
  {/if}
</div>