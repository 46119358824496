// src/functions/functions.js

// Import necessary modules
import { ChatOllama } from "@langchain/community/chat_models/ollama";
import { ChatGroq } from "@langchain/groq";
import { ChatOpenAI } from "@langchain/openai";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { loadEvaluator } from "langchain/evaluation";
import { prompt_creator } from './prompts.js';
import { getFromStorage, getJSONFromStorage } from '../utils/utils.js';
import { cacheDataWithHash, getCachedDataWithHash, getFullCachedObject } from '../utils/cache.js';

// Helper function to load aliases from storage
async function loadAliases() {
  const modelAliasesKey = 'modelAliases';
  return await getJSONFromStorage(modelAliasesKey);
}

// Function to check if a model is an alias and replace it with the full model name
async function resolveAlias(model) {
  const aliases = await loadAliases();
  return aliases[model] || model;
}

function extractInstructions(inputString) {
  const regex = /<Instructions>([\s\S]*?)<\/Instructions>/;
  const match = inputString.match(regex);
  return match ? match[1] : null;  // Returns the extracted text or null if no match is found
}

function replaceVariablesWithPositional(string) {
  const pattern = /\{\$([^\}]+)\}/g;
  let index = 0;
  const variableMap = {};

  // Replace each unique variable with a positional index
  return string.replace(pattern, (match) => {
    // Check if the variable has already been assigned a positional index
    if (!(match in variableMap)) {
      // Assign a new positional index if not already assigned
      variableMap[match] = `{${index++}}`;
    }
    return variableMap[match];
  });
}

// Implementation of the evaluation logic
async function eval_impl(chatModel, cell_to_evaluate, criteria, full_output_true_false = false, use_cache = 1) {
  use_cache = use_cache === null ? 1 : use_cache; // Ensure use_cache has a default value of 1
  const paramsObj = { model: chatModel.model, cell_to_evaluate, criteria };
  console.log("Use cache value:", use_cache);
  if (use_cache > 0) {
    const cachedData = await getCachedDataWithHash(paramsObj, use_cache - 1);
    console.log("Cached data retrieved:", cachedData);
    if (cachedData) {
      return full_output_true_false ? cachedData : JSON.parse(cachedData).score;
    }
  }
  try {
    const known_criteria = ["conciseness", "relevance", "correctness", "coherence", "harmfulness", "maliciousness", "helpfulness", "controversiality", "misogyny", "criminality",
      "insensitivity", "depth", "creativity", "detail"];

    const is_known = known_criteria.includes(criteria);
    if (!is_known)
      criteria = { custom: criteria }

    var messages = JSON.parse(cell_to_evaluate).messages;

    var evaluator = await loadEvaluator("criteria", {
      criteria: criteria,
      llm: chatModel,
    });
    const lastMessage = messages[messages.length - 1];
    const secondLastMessage = messages[messages.length - 2];
    const res = await evaluator.evaluateStrings({
      input: secondLastMessage.content,
      prediction: lastMessage.content,
    });
    const result = full_output_true_false ? JSON.stringify(res) : res.score;
    await cacheDataWithHash(paramsObj, JSON.stringify(res));
    return result;
  } catch (error) {
    console.error("Error:", error); // Logging any errors that occur
    return "An error occurred during the evaluation.";
  }
}

/**
 * Custom function to evaluate output for a previous prompt using an LLM
 * @customfunction EVAL
 * @param {string} model The model to use for the evaluation
 * @param {string} cell_to_evaluate The cell where the previous prompt to evaluate was run
 * @param {string} criteria The criteria to use for the evaluation
 * @param {boolean} full_output_true_false Full json output with reasoning, value and score (TRUE) or just score (FALSE)
 * @param {number} use_cache Optional cache index to use. Default is 1.
 * @returns {Promise<string>} The content of the URL or an error message.
 */
async function Eval(model, cell_to_evaluate, criteria, full_output_true_false = false, use_cache = 1) {
  model = await resolveAlias(model);
  const chatModel = await getChatModelObj(model);
  return eval_impl(chatModel, cell_to_evaluate, criteria, full_output_true_false, use_cache);
}

/**
 * Custom function that fetches the content of a URL in a useful format for language models
 * @customfunction READER
 * @param {string} url The URL to append and fetch.
 * @param {string} return_format The format to return the page content. Can be default, markdown, html, text, screenshot.
 * @param {number} use_cache Optional cache index to use. Default is 1.
 * @returns {Promise<string>} The content of the URL or an error message.
 */
async function Reader(url, return_format = 'default', use_cache = 1) {
  window.$salespanel.push(["set", "activity:customActivity", "customfunctions", "Reader", "Reader", {"return_format": return_format}]);
  console.log("sp");
  use_cache = use_cache === null ? 1 : use_cache; // Ensure use_cache has a default value of 1
  const paramsObj = { url, return_format };
  console.log("Use cache value:", use_cache);
  if (use_cache > 0) {
    const cachedData = await getCachedDataWithHash(paramsObj, use_cache - 1);
    console.log("Cached data retrieved:", cachedData);
    if (cachedData) {
      return cachedData;
    }
  }
  const prefixedUrl = `https://r.jina.ai/${url}`;
  const options = { headers: { 'X-Return-Format': return_format } };
  const MAX_LEN = 32760;

  return fetch(prefixedUrl, options)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network call failed.');
      }
      return response.text();
    })
    .then(txt => {
      txt = txt.replace(/[^\x20-\x7E]/g, '');
      if (txt.length > MAX_LEN) {
        txt = txt.substring(0, MAX_LEN) + '...';
      }
      cacheDataWithHash(paramsObj, txt);
      return txt;
    })
    .catch(error => {
      return `Error: ${error.message}`;
    });
}

async function GetModelInfo(model) {
  var parts = model.split('/');
  var prefix = parts[0].toLowerCase();
  var model_name = parts.slice(1).join('/');
  if (prefix == "openrouter") {
    var key = await getFromStorage("openRouterKey") || '';
    var url = 'https://openrouter.ai/api/v1/chat/completions';
    var base_url = 'https://openrouter.ai/api/v1';
  }
  if (prefix == "ollama") {
    var host = await getFromStorage("ollamaHost") || 'localhost';
    var url = 'http://' + host + ':11434/v1/chat/completions';
    var key = "";
    var base_url = 'http://' + host + ':11434';
  }
  if (prefix == "groq") {
    var key = await getFromStorage("groqKey") || '';
    var url = 'https://api.groq.com/openai/v1/chat/completions';
  }
  return {
    prefix: prefix,
    model_name: model_name,
    url: url,
    key: key,
    base_url: base_url
  }
}

async function getChatModelObj(model) {
  var modelInfo = await GetModelInfo(model);
  if (modelInfo.prefix == "openrouter") {
    return new ChatOpenAI({
      apiKey: modelInfo.key,
      configuration: {
        baseURL: modelInfo.base_url,
      },
      model: modelInfo.model_name,
    });
  }
  if (modelInfo.prefix == "ollama") {
    return new ChatOllama({
      baseUrl: modelInfo.base_url,
      model: modelInfo.model_name,
      stream: false
    });
  }
  if (modelInfo.prefix == "groq") {
    return new ChatGroq({
      apiKey: modelInfo.key,
      model: modelInfo.model_name
    });
  }
}

async function CallModel(model, prompt, full_output_true_false, continue_from_cell = null, max_tokens = null, temperature = 1, force_json_true_false = false, key, url, use_cache = 1) {
  use_cache = use_cache === null ? 1 : use_cache; // Ensure use_cache has a default value of 1
  const paramsObj = { 
    model, 
    prompt, 
    continue_from_cell: continue_from_cell || '', 
    max_tokens: max_tokens || '', 
    temperature: temperature || '', 
    force_json_true_false: force_json_true_false || '' 
  };
  console.log("Use cache value:", use_cache);
  console.log("ParamsObj for caching:", paramsObj);
  if (use_cache > 0) {
    const cachedData = await getCachedDataWithHash(paramsObj, use_cache - 1);
    console.log("Cached data retrieved:", cachedData);
    if (cachedData) {
      return full_output_true_false ? cachedData : JSON.parse(cachedData).response;
    }
  }

  const startTime = new Date(); // Capture start time
  // Parse the message history or initialize if not provided or empty
  let previousMessages;
  try {
    previousMessages = continue_from_cell ? JSON.parse(continue_from_cell).messages : [];
  } catch (e) {
    // If JSON parsing fails, default to an empty array
    previousMessages = [];
  }
  console.log(previousMessages);

  // Add the new user message to the existing messages
  previousMessages.push({
    role: "user",
    content: prompt
  });

  // Data to be sent in the request
  const data = {
    model: model,
    messages: previousMessages,
    max_tokens: max_tokens,
    temperature: temperature
  };
  if (force_json_true_false) {
    data.response_format = { type: 'json_object' };
  }

  var headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${key}`,
  }
  if (key == "") {
    headers = {
      'Content-Type': 'application/json',
    }
  }
  
  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    stream: false
  })
    .then(response => {
      if (!response.ok) {
        // If the response is not ok, throw an error with status text
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.json();
    })
    .then(async data => {
      // Append the API response to the message history
      const endTime = new Date(); // Capture end time
      const runTime = endTime - startTime;
      previousMessages.push({
        role: "assistant",
        content: data.choices[0].message.content
      });
      const cost = data.usage.total_cost;
      // Construct the full response with the updated message history
      const fullResponse = {
        cost: cost,
        runtime: runTime / 1000,
        response: data.choices[0].message.content,
        messages: previousMessages,
      };
      if (url.includes("openrouter.ai")) {
        const generation_id = data.id;
        return fetch(`https://openrouter.ai/api/v1/generation?id=${generation_id}`, {
          method: 'GET',
          headers: headers
        })
        .then(response => {
          console.log(response);
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          return response.json();
        })
        .then(generationData => {
          console.log(generationData);
          fullResponse.cost = generationData.data.total_cost;
          return cacheAndReturnResponse(fullResponse, full_output_true_false, paramsObj);
        })
        .catch(error => {
          fullResponse.cost = `Error: ${error.message}`;
          return full_output_true_false ? JSON.stringify(fullResponse) : fullResponse.response;
        });
      } else {
        return cacheAndReturnResponse(fullResponse, full_output_true_false, paramsObj);
      }
    })
    .catch(error => {
      // Return the error message directly
      // This error message will be displayed in the Excel cell
      return `Error: ${error.message}`;
    });
}

async function cacheAndReturnResponse(fullResponse, full_output_true_false, paramsObj) {
  await cacheDataWithHash(paramsObj, JSON.stringify(fullResponse));
  console.log("Cached result:", JSON.stringify(fullResponse));
  return full_output_true_false ? JSON.stringify(fullResponse) : fullResponse.response;
}



/**
 * Create a prompt for a task using different language models.
 * Model and task are required
 * @customfunction
 * @param {string} model The model to use for the completion with the prefix for the provider.
 * @param {string} task The task to create a prompt for.
 * @param {number} temperature=1 Optional temperature value to use.
 * @param {number} use_cache Optional cache index to use. Default is 1.
 * @return The suggested prompt
 */
async function CREATE_PROMPT(model, task, temperature = 1, use_cache = 1) {
  use_cache = use_cache === null ? 1 : use_cache; // Ensure use_cache has a default value of 1
  model = await resolveAlias(model);
  console.log("Calling GetModelInfo");
  console.log(model);
  const paramsObj = { model, task, temperature: temperature || 1 };
  console.log("Use cache value:", use_cache);
  if (use_cache > 0) {
    const cachedData = await getCachedDataWithHash(paramsObj, use_cache - 1);
    console.log("Cached data retrieved:", cachedData);
    if (cachedData) {
      return cachedData;
    }
  }
  var modelInfo = await GetModelInfo(model);
  console.log(modelInfo);
  try {
    // Replace placeholders in the template
    var prompt = prompt_creator.replace("{{TASK}}", task);
    console.log(prompt);

    // Call the model with the replaced prompt
    var result = await CallModel(modelInfo.model_name, prompt, false, "", null, temperature, false, modelInfo.key, modelInfo.url);
    console.log(result);

    // Extract instructions from the result
    var instructions = extractInstructions(result);
    console.log(instructions);

    // Replace variables in instructions with positional placeholders
    var final_instructions = replaceVariablesWithPositional(instructions);
    console.log(final_instructions);

    await cacheDataWithHash(paramsObj, final_instructions);
    return final_instructions;
  } catch (error) {
    // Log the error or handle it as needed
    console.log(error)
    var e = "An error occurred try using a more powerful model";
    console.log(e)
    return e;
  }
}

/**
 * Run prompts using different language models.
 * Only model (with provider prefix) and prompt are required the rest are optional
 * @customfunction
 * @param {string} model The model to use for the completion with the prefix for the provider.
 * @param {string} prompt The prompt for the model.
 * @param {boolean} full_output_true_false= Full json output with cost and runtime
 * @param {string} continue_from_cell="" Optional full output from a previous conversation to continue.
 * @param {number} max_tokens=null Optional full output from a previous conversation to continue.
 * @param {number} temperature=1 Optional temperature value to use.
 * @param {boolean} force_json_true_false If true, ensures the output is always returned as a JSON string.
 * @param {number} use_cache Optional cache index to use. Default is 1.
 * @return A promise that resolves with a JSON string including the response and the full message history.
 */
async function LM(model, prompt, full_output_true_false = false, continue_from_cell = "", max_tokens = null, temperature = 1, force_json_true_false = false, use_cache = 1) {
  use_cache = use_cache === null ? 1 : use_cache; // Ensure use_cache has a default value of 1
  model = await resolveAlias(model);
  console.log("Calling GetModelInfo");
  console.log(model);
  var modelInfo = await GetModelInfo(model);
  console.log(modelInfo);

  return CallModel(modelInfo.model_name, prompt, full_output_true_false, continue_from_cell, max_tokens, temperature, force_json_true_false, modelInfo.key, modelInfo.url, use_cache);
}

/**
 * Extracts the run time from the JSON output of OpenRouter or Ollama function.
 * Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the runtime.
 * @customfunction
 * @param {string} prompt_output_cell The cell containing the JSON output from OpenRouter() or Ollama().
 * @return The execution time extracted from the JSON string.
 */
function runtime(prompt_output_cell) {
  try {
    // Parse the JSON string from the input cell
    var resultJson = JSON.parse(prompt_output_cell);

    // Check if the runTime key exists in the JSON object
    if (resultJson.runtime !== undefined) {
      // Return the run time value
      return resultJson.runtime;
    } else {
      // If runTime is not found in the JSON, return an error message or undefined
      return "Run time not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the runtime";
    }
  } catch (e) {
    // Handle parsing errors, likely due to invalid JSON format
    return "Run time not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the runtime";
  }
}

/**
 * Extracts the response from the JSON output of OpenRouter or Ollama function.
 * Make sure to use full_output=TRUE when calling OpenRouter() or Ollama().
 * @customfunction
 * @param {string} prompt_output_cell The cell containing the JSON output from OpenRouter() or Ollama().
 * @return The response extracted from the JSON string.
 */
function response(prompt_output_cell) {
  try {
    // Parse the JSON string from the input cell
    var resultJson = JSON.parse(prompt_output_cell);

    // Check if the response key exists in the JSON object
    if (resultJson.response !== undefined) {
      // Return the response value
      return resultJson.response;
    } else {
      // If response is not found in the JSON, return an error message or undefined
      return "Response not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the response";
    }
  } catch (e) {
    // Handle parsing errors, likely due to invalid JSON format
    return "Response not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the response";
  }
}

/**
 * Extracts the cost from the JSON output of OpenRouter or Ollama function.
 * Make sure to use full_output=TRUE when calling OpenRouter() or Ollama().
 * @customfunction
 * @param {string} prompt_output_cell The cell containing the JSON output from OpenRouter() or Ollama().
 * @return The cost extracted from the JSON string.
 */
function cost(prompt_output_cell) {
  try {
    // Parse the JSON string from the input cell
    var resultJson = JSON.parse(prompt_output_cell);

    // Check if the cost key exists in the JSON object
    if (resultJson.cost !== undefined) {
      // Return the run time value
      return resultJson.cost;
    } else {
      // If cost is not found in the JSON, return an error message or undefined
      return "Cost not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the cost";
  }
  } catch (e) {
    // Handle parsing errors, likely due to invalid JSON format
    return "Cost not found in the provided data. Make sure to use full_output=TRUE when calling OpenRouter() or Ollama() to include the cost";
  }
}

/**
 * Extracts a specified field from the JSON output of OpenRouter or Ollama function.
 * Ensure to use the appropriate output format setting when calling OpenRouter() or Ollama() to include the full JSON output.
 * @customfunction
 * @param {string} prompt_output_cell The cell containing the JSON output from OpenRouter() or Ollama().
 * @param {string} field_to_extract The field to extract from the JSON.
 * @return The field extracted from the JSON string, or an error message if the field is not found or JSON is malformed.
 */
function extract_json(prompt_output_cell, field_to_extract) {
  try {
    // Parse the JSON string from the input cell
    var resultJson = JSON.parse(prompt_output_cell);

    // Check if the specified field exists in the JSON object
    if (resultJson.hasOwnProperty(field_to_extract)) {
      // Return the value of the specified field
      return resultJson[field_to_extract];
    } else {
      // If the specified field is not found in the JSON, return an error message
      return `Field '${field_to_extract}' not found in the provided data. Ensure full_output=TRUE is used when calling OpenRouter() or Ollama().`;
    }
  } catch (e) {
    // Handle parsing errors, likely due to invalid JSON format
    return `Error parsing JSON: ${e.message}. Ensure the input is a valid JSON string from OpenRouter() or Ollama().`;
  }
}

/**
 * Fills in the template with arguments.
 * @customfunction
 * @param {string} template The template string containing placeholders like {0}, {1}, etc.
 * @param {string[]} args Arguments to replace placeholders in the template.
 * @returns {string} The populated template.
 */
function FILL_TEMPLATE(template, args) {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

CustomFunctions.associate("EVAL", Eval);
CustomFunctions.associate("READER", Reader);
CustomFunctions.associate("CREATE_PROMPT", CREATE_PROMPT);
CustomFunctions.associate("LM", LM);
CustomFunctions.associate("RUNTIME", runtime);
CustomFunctions.associate("RESPONSE", response);
CustomFunctions.associate("COST", cost);
CustomFunctions.associate("EXTRACT_JSON", extract_json);
CustomFunctions.associate("FILL_TEMPLATE", FILL_TEMPLATE);