<div class="box">
    <article class="message is-info">
        <div class="message-header">
            <p>Getting Started</p>
        </div>
        <div class="message-body">
            <p><b>Step 1: </b>Connect one or more providers under the providers tab to run your models (instructions on the providers tab).</p>
            <p><b>Step 2: </b>Use the =LM function to call a model.</p>
            <p><b>Step 3: </b>Use the Cell Viewer tab to conviently view the model outputs from your prompts.</p>
            <p><b>Step 4: </b>Read more about the available functions under the functions tab (here in the help section.)</p>
            <p><b>Step 5: (optional) </b>Setup aliases and groups for your frequently used models under the aliases tab.</p>
        </div>
    </article>
</div>
<div class="box">
    <article class="message is-info">
        <div class="message-header">
            <p>Background</p>
        </div>
        <div class="message-body">
            <p>Welcome to Sheet Prompter.</p>
            <br/>
            <p>Sheet Prompter is a tool to simplify and speed up the prompt engineering process for open source LLMs.</p>
            <br/><p>This process typically entails trying different open source models and different prompts on a set of expected inputs and evaluating the results to find the best combination from a performance and cost optimization perspective.</p>
            <br/>
            <p>Sheet Prompter makes it easy to to:<br/>
            1) Quickly try out different versions of your prompts and <b>track, evaluate and compare results.</b><br/>
            2) Experiment with many <b>different open source language models</b> rapidly <b>without writing code</b> to see which works best for your prompts.<br/>
            3) Run different model\prompt combinations on any input data that you have, right in excel.<br/>
            4) Compose <b>multi step prompt chains</b> where the output of one step feeds into the next step, without writing code while viewing and tweaking inputs and <b>evaluating</b> outputs at each step of the process wight inside of excel.</p>
            <br/>
            <p>Basically it just makes it <b>easy to quickly experiment</b> with prompts, chains and models so you get high quality output at the best possible price.</p>
        </div>
    </article>
</div>

