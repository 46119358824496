<style>
  pre {
    white-space: pre-wrap;
  }
  </style>
  
  <script>
    import { onMount, onDestroy } from 'svelte';
    import { writable, derived } from 'svelte/store';
  
    let cellValue = writable('Select a cell');
    let formattedCellValue = derived(cellValue, $cellValue => {
      try {
        // Attempt to parse the cell value as JSON
        const json = JSON.parse($cellValue);
        // If parsing succeeds, format it with JSON.stringify
        return JSON.stringify(json, null, 2);
      } catch {
        // If parsing fails, it means cellValue is not a JSON string, so return it as is
        return $cellValue;
      }
    });
    let cellAddress = writable('');
  
    onMount(() => {
      Office.onReady().then(() => {
        initializeExcelEvents();
      });
    });
  
    onDestroy(() => {
      removeExcelEventHandlers();
    });
  
    async function initializeExcelEvents() {
      try {
        await Excel.run(async (context) => {
          Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, loadSelectedCell);
  
          Office.context.document.bindings.getByIdAsync("myBinding", function(result) {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                result.value.addHandlerAsync(Office.EventType.BindingDataChanged, loadSelectedCell);
            }
          });
          loadSelectedCell();
        });
      } catch (error) {
        console.error(error);
      }
    }
  
    function removeExcelEventHandlers() {
      // Add your cleanup logic here
    }
  
    async function loadSelectedCell() {
      try {
        await Excel.run(async (context) => {
          const range = context.workbook.getSelectedRange();
          range.load('values');
          range.load('address');
          await context.sync();
  
          cellValue.set(range.values[0][0] || 'Empty');
          const addressParts = range.address.split('!');
          cellAddress.set(`Sheet: ${addressParts[0]} Cell: ${addressParts[1]}`);
        });
      } catch (error) {
        console.error('Error: ' + error);
        cellValue.set('Error fetching cell value');
        cellAddress.set('');
      }
    }
  </script>
  
  <div class="box">
  <article class="message is-info">
      <div class="message-header">
        <p>Cell Viewer</p>
      </div>
      <div class="message-body">
        <p>Shows the value of the current cell</p>
        <p>Useful for example to view the result of a prompt</p>
        <br/>
        <pre class="message-body">{$formattedCellValue}</pre>
      </div>
  </article>
  </div>
  