// src/utils/cache.js

async function generateHash(paramsObj) {
    const values = Object.values(paramsObj).join('|');
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(values));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('cacheDatabase', 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('cacheStore')) {
                db.createObjectStore('cacheStore', { keyPath: 'id' });
            }
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
}

export async function cacheDataWithHash(paramsObj, data) {
    const id = await generateHash(paramsObj);
    const db = await openDatabase();
    const transaction = db.transaction(['cacheStore'], 'readwrite');
    const objectStore = transaction.objectStore('cacheStore');

    const getRequest = objectStore.get(id);

    getRequest.onsuccess = (event) => {
        const cacheObject = event.target.result || { id: id, params: paramsObj, values: [] };
        cacheObject.values.push(data);

        const putRequest = objectStore.put(cacheObject);

        putRequest.onsuccess = () => {
            console.log('Data cached successfully');
        };

        putRequest.onerror = (event) => {
            console.log('Error caching data:', event.target.errorCode);
        };
    };

    getRequest.onerror = (event) => {
        console.log('Error retrieving cache for update:', event.target.errorCode);
    };
}

export async function getCachedDataWithHash(paramsObj, index) {
    const id = await generateHash(paramsObj);
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['cacheStore'], 'readonly');
        const objectStore = transaction.objectStore('cacheStore');

        const getRequest = objectStore.get(id);

        getRequest.onsuccess = (event) => {
            const cacheObject = event.target.result;
            if (cacheObject && cacheObject.values[index] !== undefined) {
                resolve(cacheObject.values[index]);
            } else {
                resolve(null);
            }
        };

        getRequest.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
}

export async function getFullCachedObject(paramsObj) {
    const id = await generateHash(paramsObj);
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['cacheStore'], 'readonly');
        const objectStore = transaction.objectStore('cacheStore');

        const getRequest = objectStore.get(id);

        getRequest.onsuccess = (event) => {
            resolve(event.target.result);
        };

        getRequest.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
}
