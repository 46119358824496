<!-- Wizard.svelte -->
<script>
    import Variables from './Variables.svelte';
    import ModelList from './ModelList.svelte';
    import { writable } from 'svelte/store';
    import PromptEditor from './PromptEditor.svelte';

    let template = "";
    const models = writable([]);

    let variables = {
        "Use Model": "=B6",
        "Use Prompt Template": "=B7",
        "Use Eval Criteria": "=B8",
        "Use Eval Model": "=B9"
    };

    let variablesRef;
    let variableSettings = [];

    // Handle changes to variable settings from PromptEditor
    const handleVariableSettingsChange = (event) => {
        variableSettings = event.detail;
        console.log('Variable Settings in Wizard:', variableSettings);
    };

    // Find the first empty column in the current sheet with no data in the first 10 rows
    const findFirstEmptyColumn = async () => {
        try {
            return await Excel.run(async (context) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();
                const range = sheet.getRange("A1:Z10"); // Adjust range as needed
                range.load("values");

                await context.sync();

                for (let col = 0; col < range.values[0].length; col++) {
                    let isEmpty = true;
                    for (let row = 0; row < range.values.length; row++) {
                        if (range.values[row][col] !== "") {
                            isEmpty = false;
                            break;
                        }
                    }
                    if (isEmpty) {
                        return col + 1; // Returning 1-based column index
                    }
                }
                return null; // If no empty column is found
            });
        } catch (error) {
            console.error("Error finding the first empty column:", error);
        }
    };

    // Convert a column letter to the corresponding letter 2 positions ahead
    const getNextColumnLetter = (column) => {
        return String.fromCharCode(column.charCodeAt(0) + 2);
    };
    // Convert a column index to the corresponding column letter
    const getColumnLetter = (colIndex) => {
        let letter = '';
        let tempIndex = colIndex;
        while (tempIndex > 0) {
            let remainder = (tempIndex - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter;
            tempIndex = Math.floor((tempIndex - remainder) / 26);
        }
        return letter;
    };

    // Add a new column at the first available spot and return the column letter
    const addNewColumn = async (header, value) => {
        try {
            const colIndex = await findFirstEmptyColumn();
            if (colIndex !== null) {
                await Excel.run(async (context) => {
                    const sheet = context.workbook.worksheets.getActiveWorksheet();
                    const headerCell = sheet.getCell(0, colIndex - 1); // 0-based index
                    const valueCell = sheet.getCell(1, colIndex - 1); // 0-based index

                    headerCell.values = [[header]];
                    valueCell.values = [[value]];

                    await context.sync();
                });
                return getColumnLetter(colIndex);
            } else {
                console.error("No empty column found.");
            }
        } catch (error) {
            console.error("Error adding new column:", error);
        }
        return null;
    };

    // Generate button function
    const generateVariables = async () => {
        variables["Models"] = [...$models];
        variables["Prompt Template"] = template;
        variables["Eval Criteria"] = [
            'conciseness', 'relevance', 'correctness', 'coherence', 'harmfulness', 'maliciousness',
            'helpfulness', 'controversiality', 'misogyny', 'criminality', 'insensitivity', 'depth', 
            'creativity', 'detail'
        ];
        variables["Eval Models"] = [...$models];
        await variablesRef.updateExcel();

        let completion_formula = `=lm($B$2,$B$3,TRUE)`;

        if (variableSettings.length > 0) {
            let formula = `=fill_template($B$3`;
            for (const setting of variableSettings) {
                formula += `,$${getNextColumnLetter(setting.column)}2`;
            }
            formula += `)`;
            const promptTemplateColumn = await addNewColumn("Prompt", formula);

            completion_formula = `=lm($B$2,`;
            completion_formula += `$${promptTemplateColumn}2,TRUE)`;
        }
        const completionColumn = await addNewColumn("Full Result", completion_formula);
        let result_formula = `=response($${completionColumn}2)`;
        await addNewColumn("Result", result_formula);

        let cost_formula = `=cost($${completionColumn}2)`;
        await addNewColumn("Cost", cost_formula);

        let runtime_formula = `=runtime($${completionColumn}2)`;
        await addNewColumn("Runtime", runtime_formula);

        let eval_formula = `=Eval($b$5, $${completionColumn}2, $b$4)`;
        await addNewColumn("Eval", eval_formula);
    };
</script>

<article class="message is-info">
    <div class="message-header">
        <p>Wizard</p>
    </div>
    <div class="message-body">
        <Variables bind:this={variablesRef} {variables} />
        <PromptEditor bind:value={template} on:variableSettingsChange={handleVariableSettingsChange} />
        <ModelList bind:modelList={$models} />
        <div class="button-container">
            <button class="button is-info" on:click={generateVariables}>Generate</button>
        </div>
        <style>
            p.template {
                margin-top: 1rem;
                font-size: 1.2rem;
            }
            .variable-settings {
                margin-top: 1rem;
            }
            .variable-settings ul {
                list-style-type: none;
                padding: 0;
            }
            .variable-settings li {
                margin: 0.5rem 0;
                font-size: 1.1rem;
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 1rem;
            }
        </style>
    </div>
</article>
