// stores.js
import { writable, derived } from 'svelte/store';

// List of models from the first component
export const ollamaModels = writable([]);

// Fetch models for Ollama
async function fetchOllamaModels() {
  try {
    const response = await fetch('http://localhost:11434/api/tags');
    if (!response.ok) throw new Error('Failed to fetch');

    const data = await response.json();
    ollamaModels.set(data.models);
  } catch (error) {
    console.error('API call failed: ', error);
    ollamaModels.set([]);
  }
}

fetchOllamaModels();

// List from the second component
export const openrouterModels = writable([]);

// Fetch models for OpenRouter
async function fetchOpenRouterModels() {
  try {
    const response = await fetch('https://openrouter.ai/api/v1/models');
    if (!response.ok) throw new Error('Network response was not ok');

    const data = await response.json();
    openrouterModels.set(data.data || []);
    openrouterModels.update(models => {
      models.forEach(model => model.size = extractSize(model.id));
      return models;
    });
  } catch (error) {
    console.error('Fetch error:', error.message);
    openrouterModels.set([]);
  }
}

fetchOpenRouterModels();

// Extract size from model ID
function extractSize(id) {
  const regex = /(\d+x)?(\d+b)/i;
  const parts = id.split('/');
  const lastSegment = parts.pop();
  const match = lastSegment.match(regex);
  return match ? (match[1] ? match[1] + match[2] : match[2]) : 'N/A';
}

// List from the third component
export const groqModels = writable([
  { id: 'llama3-8b-8192', name: 'Llama3 8B 8192', size: '8B', description: '', context_length: '8192', pricing: { prompt: '', completion: '' } },
  { id: 'llama3-70b-8192', name: 'Llama3 70B 8192', size: '70B', description: '', context_length: '8192', pricing: { prompt: '', completion: '' } },
  { id: 'mixtral-8x7b-32768', name: 'Mixtral 8x7B 32768', size: '8x7B', description: '', context_length: '32768', pricing: { prompt: '', completion: '' } },
  { id: 'gemma-7b-it', name: 'Gemma 7B IT', size: '7B', description: '', context_length: '8192', pricing: { prompt: '', completion: '' } }
]);

// Combined list
export const combinedList = derived(
  [ollamaModels, openrouterModels, groqModels],
  ([$ollamaModels, $openrouterModels, $groqModels]) => {
    // Prepend the appropriate prefix to each model
    const prependedOllamaModels = $ollamaModels.map(model => ({ ...model, name: `ollama/${model.name}` }));
    const prependedOpenrouterModels = $openrouterModels.map(model => ({ ...model, name: `openrouter/${model.id}` }));
    const prependedGroqModels = $groqModels.map(model => ({ ...model, name: `groq/${model.id}` }));

    // Combine all lists into one
    return [...prependedOllamaModels, ...prependedOpenrouterModels, ...prependedGroqModels];
  }
);
