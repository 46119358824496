<script>
    import { writable } from 'svelte/store';
  
    let selectedRow = writable(null);
    let copyMessage = writable('');
  
    function toggleRow(name) {
      selectedRow.update(current => (current === name ? null : name));
    }
  
    async function createDropdownInCell() {
      await Excel.run(async (context) => {
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const range = context.workbook.getSelectedRange();
        const criteria = ['conciseness','relevance','correctness','coherence','harmfulness','maliciousness','helpfulness',
              'controversiality','misogyny','criminality','insensitivity','depth','creativity','detail'];
        range.dataValidation.rule = {
          list: {
            inCellDropDown: true,
            source: criteria.join(',')
          }
        };
        range.values = [['conciseness']];
        await context.sync();
      });
    }
  
    async function copyToCell(text) {
      await Excel.run(async (context) => {
        const range = context.workbook.getSelectedRange();
        range.values = [[text]];
        await context.sync();
        copyMessage.set('Example copied to current cell');
        setTimeout(() => copyMessage.set(''), 3000);
      });
    }
  </script>
  
  <style>
  .accordion-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: none;
    width: 100%;
    text-align: left;
  }
  
  .accordion-content {
    padding: 1rem;
    border: 1px solid #00d1b2;
    border-top: none;
    background-color: #eef6fc; /* Light blue background */
  }
  .copy-icon {
    cursor: pointer;
    margin-left: 1rem;
  }
  .notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
  }
  </style>
  
  {#each [
    {
      name: 'LM',
      purpose: 'This function runs a prompt using the specified language model from one of the providers.',
      basicUsage: '=LM(model, prompt)',
      advancedUsage: '=LM(model, prompt, full_output_true_false, continue_from_cell, max_tokens, temperature, force_json_true_false)',
      parameters: [
        { name: 'model', description: 'The provider name followed by slash followed by the full model name. Alternatively an Alias (create through the alias tab)' },
        { name: 'prompt', description: 'The prompt for the model.' },
        { name: 'full_output_true_false', description: 'Boolean. If TRUE, returns full JSON output including cost and runtime; if FALSE, returns just the text response. Default FALSE.' },
        { name: 'continue_from_cell', description: 'Cell address to continue the conversation from a previous call to LM (must use full_output_true_false=TRUE in the previous call).' },
        { name: 'max_tokens', description: 'Maximum number of tokens for the model’s response.' },
        { name: 'temperature', description: 'Controls the randomness of the response. Higher values are more random or more creative. Zero is deterministic.' },
        { name: 'force_json_true_false', description: 'Boolean. Forces the output to be in JSON format if TRUE. You must also ask for JSON in your prompt.' },
      ],
      example: '=LM("openrouter/mistralai/mistral-7b-instruct:free", "tell me a joke about a dog")'
    },
    {
      name: 'CREATE_PROMPT',
      purpose: 'This function takes a prompt or a task that you want to achieve and creates an enhanced prompt for you.',
      basicUsage: '=CREATE_PROMPT(model, task, temperature)',
      parameters: [
        { name: 'model', description: 'The provider name followed by slash followed by the full model name. Alternatively an Alias (create through the alias tab)' },
        { name: 'task', description: 'The prompt or task text to create an enhanced prompt for.' },
        { name: 'temperature', description: 'Controls the randomness of the response. Higher values are more random or more creative. Zero is deterministic.' },
      ],
      example: '=CREATE_PROMPT("openrouter/anthropic/claude-3-opus", "review the given website")'
    },
    {
      name: 'READER',
      purpose: 'This function takes a url and downloads the text in a format easily usable for a language model for use in your prompts.',
      basicUsage: '=READER(url, return_format)',
      parameters: [
        { name: 'url', description: 'The URL of the page to read.' },
        { name: 'return_format', description: 'The format to return the page content. Can be default, markdown, html, text.' },
      ],
      example: '=READER("https://www.google.com")'
    },
    {
      name: 'EVAL',
      purpose: 'This function evaluates a response from a previous LM call using a specified criteria.',
      basicUsage: '=EVAL(model, cell_to_evaluate, criteria, full_output_true_false)',
      parameters: [
        { name: 'model', description: 'The provider name followed by slash followed by the full model name. Alternatively an Alias (create through the alias tab)' },
        { name: 'cell_to_evaluate', description: 'The cell which contains the previous prompt (=LM) with full_output_true_false set to TRUE.' },
        { name: 'criteria', description: 'See below the list of available criteria. You can also enter your own custom criteria here (as a full description).' },
        { name: 'full_output_true_false', description: 'Boolean. If TRUE, returns full JSON output including cost and runtime; if FALSE, returns just the text response. Default FALSE.' },
      ],
      example: '=EVAL("openrouter/openai/gpt-3.5-turbo", a2, "conciseness")',
      extraInfo: [
        { name: 'Available Criteria', description: '' },
        { name: 'conciseness', description: 'Evaluates if the response is succinct, without unnecessary details or verbosity.' },
        { name: 'relevance', description: 'Checks how relevant the response is to the input prompt or question.' },
        { name: 'correctness', description: 'Measures the accuracy of the information or the appropriateness of the response given the context.' },
        { name: 'coherence', description: 'Assesses whether the response is logically consistent and flows smoothly.' },
        { name: 'harmfulness', description: 'Detects any harmful content in the response, including offensive or inappropriate language.' },
        { name: 'maliciousness', description: 'Looks for indications of malicious intent, such as promoting harmful actions or misinformation.' },
        { name: 'helpfulness', description: 'Evaluates how useful the response is in terms of solving the user’s query or problem.' },
        { name: 'controversiality', description: 'Identifies whether the response might be considered controversial or polarizing.' },
        { name: 'misogyny', description: 'Checks for any misogynistic content in the response.' },
        { name: 'criminality', description: 'Identifies any criminal suggestions or endorsements in the response.' },
        { name: 'insensitivity', description: 'Measures the lack of sensitivity or tact in the response, particularly in contextually delicate situations.' },
        { name: 'depth', description: 'Assesses the depth of insight or detailed understanding displayed in the response.' },
        { name: 'creativity', description: 'Evaluates the novelty or creative aspect of the response.' },
        { name: 'detail', description: 'Measures the level of detail provided in the response, checking for thoroughness and elaboration.' },
      ],
      hasDropdown: true,
    },
    {
      name: 'RUNTIME',
      purpose: 'This function extracts the time it took for a prompt to run from the json in the cell.',
      importantNote: 'You must set full_output_true_false to TRUE when calling the LM function in the cell that this references.',
      basicUsage: '=RUNTIME(prompt_output_cell)',
      parameters: [
        { name: 'prompt_output_cell', description: 'The cell where the prompt was run using the LM function.' },
      ],
      example: '=RUNTIME(a2)'
    },
    {
      name: 'COST',
      purpose: 'This function extracts the cost incurred for a prompt that was run from the full output json in the cell.',
      importantNote: 'You must set full_output_true_false to TRUE when calling the LM function in the cell that this references.',
      basicUsage: '=COST(prompt_output_cell)',
      parameters: [
        { name: 'prompt_output_cell', description: 'The cell where the prompt was running using the LM function.' },
      ],
      example: '=COST(a2)'
    },
    {
      name: 'RESPONSE',
      purpose: 'This function extracts the response from a full json result in a cell where LM was called.',
      note: 'This is only useful if you set full_output_true_false to TRUE when calling the LM function in the cell that this references.',
      basicUsage: '=RESPONSE(prompt_output_cell)',
      parameters: [
        { name: 'prompt_output_cell', description: 'The cell where the prompt was run using the LM function.' },
      ],
      example: '=RESPONSE(a2)'
    },
    {
      name: 'FILL_TEMPLATE',
      purpose: 'This function fills in a template string with arguments and returns the result.',
      basicUsage: '=FILL_TEMPLATE(template, arg1, arg2, ...)',
      parameters: [
        { name: 'template', description: 'A string with placeholders like {0}, {1}, etc..' },
        { name: 'arg1', description: 'Value to use in place of {0}' },
        { name: 'arg2', description: 'Value to use in place of {1}' },
        { name: 'arg3', description: 'Value to use in place of {2}' },
      ],
      example: '=FILL_TEMPLATE("Hello, {0}!", a2)'
    },
    {
      name: 'EXTRACT_JSON',
      purpose: 'This function extracts any top level field from json text in a cell. For example from a full result of calling LM.',
      importantNote: 'You must set full_output_true_false to TRUE if calling the LM function in the cell that this references.',
      basicUsage: '=EXTRACT_JSON(prompt_output_cell, field_to_extract)',
      parameters: [
        { name: 'prompt_output_cell', description: 'The cell where the prompt was running using the LM function.' },
        { name: 'field_to_extract', description: 'The field to extract from the JSON.' },
      ],
      example: '=EXTRACT_JSON(a2, "cost")'
    }
  ] as func}
  <div class="box">
    <article class="message is-info">
      <button class="accordion-header message-header is-info" on:click={() => toggleRow(func.name)}>
        <div style="display: flex; align-items: center;">
          {#if $selectedRow === func.name}
            <i class="fas fa-chevron-down accordion-icon"></i> <!-- Expanded Icon -->
          {:else}
            <i class="fas fa-chevron-right accordion-icon"></i> <!-- Collapsed Icon -->
          {/if}
          <p style="margin: 0;">{func.name}</p>
        </div>
        <div style="flex-grow: 1; margin-left: 1rem;">
          <p style="margin: 0;">{func.purpose}</p>
        </div>
        {#if func.hasDropdown}
          <button class="button is-info is-inverted is-small" on:click={(event) => { event.stopPropagation(); createDropdownInCell(); }}>Create Criteria Dropdown</button>
        {/if}
        <i class="fas fa-copy copy-icon" title="Copy example" on:click={(event) => { event.stopPropagation(); copyToCell(func.example); }}></i>
      </button>
      {#if $selectedRow === func.name}
      <div class="accordion-content message-body">
        <p><b>Purpose:</b> {func.purpose}</p>
        <p><b>Basic Usage:</b> {func.basicUsage}</p>
        {#if func.advancedUsage}
        <p><b>Advanced Usage:</b> {func.advancedUsage}</p>
        {/if}
        <p><u>Parameters</u></p>
        {#each func.parameters as param}
        <p><b>{param.name}:</b> {param.description}</p>
        {/each}
        {#if func.example}
        <p><b>Example:</b></p>
        <pre class="message-body">{func.example}</pre>
        {/if}
        {#if func.extraInfo}
        {#each func.extraInfo as info}
        <p><b>{info.name}:</b> {info.description}</p>
        {/each}
        {/if}
      </div>
      {/if}
    </article>
  </div>
  {/each}
  
  {#if $copyMessage}
    <div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
      {$copyMessage}
    </div>
  {/if}
  