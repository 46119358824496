<script>
    import { writable } from 'svelte/store';
    import OpenRouter from './OpenRouter.svelte';
    import Ollama from './Ollama.svelte';
    import Groq from './Groq.svelte';

    let activeProviderTab = writable('openrouter');
</script>

<div class="tabs">
  <ul>
    <li class:is-active={$activeProviderTab === 'openrouter'}>
      <a id="openrouter" role="button" tabindex="0" on:click={() => activeProviderTab.set('openrouter')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('openrouter')}>OpenRouter</a>
    </li>
    <li class:is-active={$activeProviderTab === 'ollama'}>
      <a id="ollama" role="button" tabindex="0" on:click={() => activeProviderTab.set('ollama')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('ollama')}>Ollama</a>
    </li>
    <li class:is-active={$activeProviderTab === 'groq'}>
      <a id="groq" role="button" tabindex="0" on:click={() => activeProviderTab.set('groq')} on:keydown={(event) => event.key === 'Enter' && activeProviderTab.set('groq')}>Groq</a>
    </li>
  </ul>
</div>

<div>
  {#if $activeProviderTab === 'openrouter'}
    <OpenRouter />
  {:else if $activeProviderTab === 'ollama'}
    <Ollama />
  {:else if $activeProviderTab === 'groq'}
    <Groq />
  {/if}
</div>
