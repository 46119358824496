<!-- AliasManager.svelte -->
<script>
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { getJSONFromStorage, putJSONInStorage } from '../utils/utils.js';
  import Autocomplete from './Autocomplete.svelte';

  const modelAliasesKey = 'modelAliases';
  const aliasGroupsKey = 'aliasGroups';

  const aliases = writable({});
  const groups = writable({});
  const notification = writable('');

  let newAlias = '';
  let newModelName = '';
  let newGroupName = '';
  let isInitialized = false;
  let newGroupInputVisible = false;
  let tempNewGroupName = '';
  let aliasToAddToNewGroup = '';

  async function loadData() {
    const storedAliases = await getJSONFromStorage(modelAliasesKey);
    const storedGroups = await getJSONFromStorage(aliasGroupsKey);
    console.log('Loaded aliases from storage:', storedAliases);
    console.log('Loaded groups from storage:', storedGroups);
    aliases.set(storedAliases);
    groups.set(storedGroups);
    isInitialized = true;
  }

  async function saveAliases(value) {
    if (isInitialized) {
      await putJSONInStorage(modelAliasesKey, value);
      console.log('Saved aliases to storage:', value);
    }
  }

  async function saveGroups(value) {
    if (isInitialized) {
      await putJSONInStorage(aliasGroupsKey, value);
      console.log('Saved groups to storage:', value);
    }
  }

  function showNotification(message) {
    notification.set(message);
    setTimeout(() => notification.set(''), 3000);
  }

  onMount(() => {
    loadData();
  });

  aliases.subscribe(value => {
    saveAliases(value);
  });

  groups.subscribe(value => {
    saveGroups(value);
  });

  function addAlias() {
    console.log("newAlias: "+newAlias);
    console.log("newModelName: "+newModelName);
    if (newAlias && newModelName) {
      aliases.update(current => {
        current[newAlias] = newModelName;
        return current;
      });
      newAlias = '';
      newModelName = '';
      showNotification('Alias added successfully.');
    }
  }

  function editAlias(oldAlias, newAlias, newModelName) {
    aliases.update(current => {
      if (oldAlias !== newAlias) {
        delete current[oldAlias];
      }
      current[newAlias] = newModelName;
      return current;
    });
    groups.update(current => {
      for (let groupName in current) {
        const group = current[groupName];
        const index = group.indexOf(oldAlias);
        if (index !== -1) {
          group[index] = newAlias;
        }
      }
      return current;
    });
  }

  function deleteAlias(alias) {
    aliases.update(current => {
      delete current[alias];
      return current;
    });
    groups.update(current => {
      for (let groupName in current) {
        current[groupName] = current[groupName].filter(item => item !== alias);
      }
      return current;
    });
    showNotification('Alias deleted successfully.');
  }

  function addGroup() {
    if (newGroupName) {
      groups.update(current => {
        current[newGroupName] = [];
        return current;
      });
      newGroupName = '';
      showNotification('Group created successfully.');
    }
  }

  function editGroup(oldGroupName, newGroupName) {
    groups.update(current => {
      current[newGroupName] = current[oldGroupName];
      delete current[oldGroupName];
      return current;
    });
  }

  function deleteGroup(groupName) {
    groups.update(current => {
      delete current[groupName];
      return current;
    });
    showNotification('Group deleted successfully.');
  }

  function addAliasToGroup(alias, groupName) {
    if (groupName === 'new') {
      newGroupInputVisible = true;
      aliasToAddToNewGroup = alias;
    } else {
      groups.update(current => {
        if (!current[groupName]) {
          current[groupName] = [];
        }
        if (!current[groupName].includes(alias)) {
          current[groupName].push(alias);
        }
        return current;
      });
      showNotification('Alias added to group successfully.');
    }
  }

  function confirmAddToNewGroup() {
    if (tempNewGroupName) {
      groups.update(current => {
        if (!current[tempNewGroupName]) {
          current[tempNewGroupName] = [];
        }
        if (!current[tempNewGroupName].includes(aliasToAddToNewGroup)) {
          current[tempNewGroupName].push(aliasToAddToNewGroup);
        }
        return current;
      });
      newGroupInputVisible = false;
      tempNewGroupName = '';
      aliasToAddToNewGroup = '';
      showNotification('Alias added to new group successfully.');
    }
  }

  function cancelAddToNewGroup() {
    newGroupInputVisible = false;
    tempNewGroupName = '';
    aliasToAddToNewGroup = '';
  }

  function deleteAliasFromGroup(alias, groupName) {
    groups.update(current => {
      current[groupName] = current[groupName].filter(item => item !== alias);
      return current;
    });
    showNotification('Alias removed from group successfully.');
  }

  function copyToClipboard(aliases, delimiter) {
    navigator.clipboard.writeText(aliases.join(delimiter));
    showNotification('Aliases copied to clipboard.');
  }

  async function createDropdownInCell(groupName) {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = context.workbook.getSelectedRange();
      const aliases = $groups[groupName];
      range.dataValidation.rule = {
        list: {
          inCellDropDown: true,
          source: aliases.join(',')
        }
      };
      range.values = [[aliases[0]]];
      await context.sync();
      showNotification('Dropdown created in cell successfully.');
    });
  }
</script>

<style>
  .container {
    max-width: 800px;
    margin: auto;
  }
  .alias-list {
    margin-top: 1rem;
  }
  .alias-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .alias-input {
    flex: 1;
    margin-right: 0.5rem;
  }
  .model-name-input {
    flex: 5;
    margin-right: 0.5rem;
  }
  .autocomplete-container {
    flex: 5;
    margin-right: 0.5rem;
    position: relative;
  }
  .group-select {
    flex: 0.2;
    max-width: 150px;
    margin-right: 0.5rem;
  }
  .message-header-buttons {
    display: flex;
    gap: 0.5rem;
  }
  .delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  .group-delete-icon {
    color: red;
    cursor: pointer;
  }
  .notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
  }
</style>

<div class="container">
  {#if $notification}
    <div class="notification is-success is-light">
      {$notification}
    </div>
  {/if}

  <section class="message is-info">
    <div class="message-header">
      <p>Alias Management</p>
    </div>
    <div class="message-body">
      Create shorter aliases for models that you use frequently in custom functions (LM or Eval).<br/>
      With groups, you can use a group of these models collectively.<br>
      <ul class="alias-list">
        {#each Object.entries($aliases) as [alias, modelName]}
          <li class="alias-row">
            <input class="input alias-input" type="text" value={alias} placeholder="Alias" on:input={(e) => editAlias(alias, e.target.value, modelName)} />
            <input class="input model-name-input" type="text" value={modelName} placeholder="Model Name" on:input={(e) => editAlias(alias, alias, e.target.value)} />
            <div class="control group-select">
              <div class="select">
                <select on:change={(e) => addAliasToGroup(alias, e.target.value)}>
                  <option value="">Add to Group</option>
                  {#each Object.keys($groups) as groupName}
                    <option value={groupName}>{groupName}</option>
                  {/each}
                  <option value="new">Create New Group</option>
                </select>
              </div>
            </div>
            <button class="delete-icon" on:click={() => deleteAlias(alias)} aria-label="Delete alias">
              <i class="fas fa-trash"></i>
            </button>
          </li>
        {/each}
      </ul>
      <div class="alias-row">
        <input class="input alias-input" type="text" bind:value={newAlias} placeholder="New Alias" />
        <div class="autocomplete-container">
          <Autocomplete bind:selectedModel={newModelName} placeholder="New Model Name" />
        </div>
        <div class="control">
          <button class="button is-info" on:click={addAlias}>Add Alias</button>
        </div>
      </div>

      {#if newGroupInputVisible}
        <div class="field">
          <label class="label" for="new-group-name">New Group Name</label>
          <div class="control">
            <input class="input" id="new-group-name" type="text" bind:value={tempNewGroupName} placeholder="Enter new group name" />
          </div>
          <div class="control">
            <button class="button is-info" on:click={confirmAddToNewGroup}>Add</button>
            <button class="button is-danger" on:click={cancelAddToNewGroup}>Cancel</button>
          </div>
        </div>
      {/if}

      <div class="alias-row">
        <input class="input alias-input" type="text" bind:value={newGroupName} placeholder="New Group Name" />
        <div class="control">
          <button class="button is-info" on:click={addGroup}>Create Group</button>
        </div>
      </div>
    </div>
  </section>

  <section class="message is-info">
    <div class="message-header">
      <p>Groups</p>
    </div>
  </section>

  {#each Object.keys($groups) as groupName}
    <section class="message is-info">
      <div class="message-header">
        <p>{groupName}</p>
        <div class="message-header-buttons">
          <button class="button is-info is-inverted is-small" on:click={() => copyToClipboard($groups[groupName], '\t')}>Copy to Row</button>
          <button class="button is-info is-inverted is-small" on:click={() => copyToClipboard($groups[groupName], '\n')}>Copy to Column</button>
          <button class="button is-info is-inverted is-small" on:click={() => createDropdownInCell(groupName)}>Create Dropdown in Cell</button>
          <button class="group-delete-icon" on:click={() => deleteGroup(groupName)} aria-label="Delete group">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="message-body">
        <ul>
          {#each $groups[groupName] as alias}
            <li class="alias-row">
              {alias}
              <button class="button is-danger is-small" on:click={() => deleteAliasFromGroup(alias, groupName)}>Delete</button>
            </li>
          {/each}
        </ul>
      </div>
    </section>
  {/each}
</div>