<!-- Variables.svelte -->
<script>
    import { createEventDispatcher } from 'svelte';

    export let variables = {};
    const dispatch = createEventDispatcher();

    // Function to create a dropdown in a cell
    function createDropdownInCell(valueCell, arr) {
        valueCell.dataValidation.rule = {
            list: {
                inCellDropDown: true,
                source: arr.join(',')
            }
        };
        valueCell.values = [[arr[0]]];
    }

    // Function to update the Excel sheet
    export async function updateExcel() {
        await Excel.run(async (context) => {
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            const range = sheet.getRange("A1");
            range.load("values");

            await context.sync();

            if (range.values[0][0] !== "Variables") {
                // Insert 2 columns to the left
                sheet.getRange("A:B").insert("Right");

                // Set A1 to "Variables" in bold
                const headerCell = sheet.getRange("A1");
                headerCell.values = [["Variables"]];
                headerCell.format.font.bold = true;

                let rowIndex = 2; // Start from the second row
                for (let key in variables) {
                    if (variables.hasOwnProperty(key)) {
                        const keyCell = sheet.getRange(`A${rowIndex}`);
                        const valueCell = sheet.getRange(`B${rowIndex}`);

                        keyCell.values = [[key]];

                        if (Array.isArray(variables[key])) {
                            createDropdownInCell(valueCell, variables[key]);
                        } else {
                            valueCell.values = [[variables[key]]];
                        }

                        rowIndex++;
                    }
                }

                // Set the width of columns A and B
                sheet.getRange("A:A").format.columnWidth = 150; // Adjust the width as needed
                sheet.getRange("B:B").format.columnWidth = 150; // Adjust the width as needed
            }

            await context.sync();
            dispatch('updated', { success: true });
        }).catch(error => {
            console.error(error);
            dispatch('updated', { success: false, error });
        });
    }
</script>
