<script>
  import GroqSettings from './GroqSettings.svelte';
  import { onMount, tick } from 'svelte';
  import { writable, get } from 'svelte/store';
  import { getJSONFromStorage, putJSONInStorage } from '../utils/utils.js';
  import { groqModels } from '../utils/stores.js';

  const modelAliasesKey = 'modelAliases';

  const aliases = writable({});
  const aliasSuccessMessage = writable(''); // New store for success message
  
  let filteredModels = []; // Array for filtered models
  let filterText = ''; // Variable for filter text input
  let copyMessage = '';
  let showMessage = false;
  let selectedRow = null;
  let aliasInput = ''; // Alias input for the selected model

  async function loadAliases() {
    const storedAliases = await getJSONFromStorage(modelAliasesKey);
    aliases.set(storedAliases);
    console.log('Loaded aliases from storage:', storedAliases);
  }

  async function saveAliases(value) {
    await putJSONInStorage(modelAliasesKey, value);
    console.log('Saved aliases to storage:', value);
  }

  onMount(() => {
    loadAliases();
  });

  aliases.subscribe(value => {
    // Prevent saving immediately after loading
    if (selectedRow !== null) {
      saveAliases(value);
    }
  });

  function toggleRowSelection(modelId) {
    if (selectedRow === modelId) {
      selectedRow = null;
      aliasInput = ''; // Clear the alias input when collapsing
    } else {
      selectedRow = modelId;
      const currentAliases = get(aliases);
      aliasInput = Object.keys(currentAliases).find(key => currentAliases[key] === `groq/${modelId}`) || '';
    }
  }

  async function copyToClipboard(id, event) {
    event.stopPropagation(); // Prevent event bubbling
    const textToCopy = "groq/" + id;

    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('Text copied successfully using Clipboard API');
      showCopyMessage('Copied to Clipboard.');
    } catch (err) {
      console.error('Failed to copy using Clipboard API: ', err);

      // Fallback to using execCommand for environments where Clipboard API is not permitted
      try {
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textarea);

        if (successful) {
          console.log('Text copied successfully using execCommand');
          showCopyMessage('Copied to Clipboard.');
        } else {
          console.error('Failed to copy using execCommand');
          showCopyMessage('Failed to copy text.');
        }
      } catch (err) {
        console.error('Fallback failed, could not copy text: ', err);
        showCopyMessage('Failed to copy text.');
      }
    }
  }

  function showCopyMessage(message) {
    copyMessage = message;
    showMessage = true;
    setTimeout(() => {
      showMessage = false;
    }, 3000); // Hide the message after 3 seconds
  }

  function addAliasForModel(modelId) {
    const fullModelId = `groq/${modelId}`;
    aliases.update(current => {
      // Find the existing alias for this model, if any, and remove it
      const existingAliasKey = Object.keys(current).find(key => current[key] === fullModelId);
      if (existingAliasKey && existingAliasKey !== aliasInput) {
        delete current[existingAliasKey];
      }

      // Add the new alias if it is not empty
      if (aliasInput) {
        current[aliasInput] = fullModelId;
      }

      return current;
    });

    // Display success message
    aliasSuccessMessage.set(`Alias "${aliasInput}" saved for model "${modelId}".`);

    // Clear the success message after 3 seconds
    setTimeout(() => {
      aliasSuccessMessage.set('');
    }, 3000);
  }

  async function copyToCell(text) {
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.values = [[text]];
      await context.sync();
    });
  }
  // Reactive filtering
  $: {
    console.log("Filtering with:", filterText);
    filteredModels = get(groqModels).filter(model => model.id.toLowerCase().includes(filterText.toLowerCase()) ||
                                            model.name.toLowerCase().includes(filterText.toLowerCase()));
    console.log("Filtered Models:", filteredModels);
  }
</script>

<style>
  tr:hover { background-color: #f5f5f5; } /* Hover state */
  tr.selectedRow { background-color: #e9ecef; } /* Expanded state */
  .alias-container {
    display: flex;
    align-items: center;
  }
  .alias-container .input {
    margin-right: 0.5rem;
    flex: 1;
  }
</style>

<article class="message is-info">
  <div class="message-header">
    <p>Groq Setup</p>
  </div>
  <div class="message-body">
    <p><a href="https://groq.com/" target="_blank">Groq</a> is a platform to run LLMs with super fast inference. Currently available for free.</p>
    <br/>To use Groq, you'll need to create an account and then create <a target="_blank" href="https://console.groq.com/keys">an API key</a> and then enter it below:
    <br/><br/><GroqSettings />
  </div>
</article>
<article class="message is-info">
  <div class="message-header">
    <p>Using Groq</p>
  </div>
  <div class="message-body">
    <p><strong>=LM(MODEL_ID, PROMPT)</strong></p>
    <br/>Example:
    <p><strong>=LM("groq/llama3-70b-8192", "tell me a joke")</strong><br/><br/><button class="button is-info is-small" on:click={() => copyToCell('=LM("groq/llama3-70b-8192", "tell me a joke")')}>Copy to Cell</button></p>
  </div>
</article>
<article class="message is-info">
  <div class="message-header columns">
    <div class="column is-one-third">
      <p>Groq Models</p>
    </div>
    <div class="column is-two-thirds">
      <input class="input is-small" type="text" placeholder="Filter models by ID or name" bind:value={filterText}>
    </div>
  </div>
  <!-- Models Table -->
  <div class="table-container message-body">
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead class="info">
        <tr>
          <th></th> <!-- Placeholder for the expand/collapse icon -->
          <th></th><!-- Placeholder for the Copy button -->
          <th>ID</th>
          <th>Name</th>
          <!--<th>Description</th>-->
          <th>Size</th>
        </tr>
      </thead>
      <tbody class="info-light">
        {#each filteredModels as model}
          <tr on:click={() => toggleRowSelection(model.id)} class:selectedRow={selectedRow === model.id}>
            <td>
              <span class="icon">
                {#if selectedRow === model.id}
                  <i class="fas fa-chevron-down"></i> <!-- Expanded Icon -->
                {:else}
                  <i class="fas fa-chevron-right"></i> <!-- Collapsed Icon -->
                {/if}
              </span>
            </td>
            <td>
              <button on:click={(event) => copyToClipboard(model.id, event)} class="button is-small is-info">Copy</button>
            </td>
            <td>groq/{model.id}</td>
            <td>{model.name}</td>
            <!--<td>{model.description}</td>-->
            <td>{model.size}</td>
          </tr>
          {#if selectedRow === model.id}
            <tr>
              <td colspan="5">
                <div>
                  <p><strong>Description:</strong> {model.description}</p>
                  <p><strong>Context length:</strong> {model.context_length}</p>
                  <p><strong>Price per input token:</strong> {model.pricing.prompt} <strong>Price per output token:</strong> {model.pricing.completion}</p>
                  <!-- Add more model details here -->
                  <!-- Alias assignment box -->
                  <div class="field alias-container">
                    <input class="input" id="alias-{model.id}" type="text" bind:value={aliasInput} placeholder="Enter alias" />
                    <button class="button is-info" on:click={() => addAliasForModel(model.id)}>Save Alias</button>
                  </div>
                </div>
              </td>
            </tr>
          {/if}
        {/each}
      </tbody>
    </table>
  </div>
</article>
<!-- Display the feedback message -->
{#if showMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {copyMessage}
</div>
{/if}

<!-- Display the alias success message -->
{#if $aliasSuccessMessage}
<div class="notification is-success is-light" style="position: fixed; bottom: 20px; right: 20px; z-index: 100;">
  {$aliasSuccessMessage}
</div>
{/if}
