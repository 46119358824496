<script>
    import { onMount } from 'svelte';
  
    let snapshotName = "";
  
    onMount(() => {
      Office.onReady((info) => {
        if (info.host === Office.HostType.Excel) {
          console.log("Excel is ready");
        }
      });
    });
  
    async function createSnapshot() {
      try {
        await Excel.run(async (context) => {
          const currentSheet = context.workbook.worksheets.getActiveWorksheet();
          const usedRange = currentSheet.getUsedRange();
          usedRange.load(['values', 'formulas', 'rowCount', 'columnCount']);
          await context.sync();
  
          const snapshot = context.workbook.worksheets.add(snapshotName);
          const targetRange = snapshot.getRange("A1").getResizedRange(usedRange.rowCount - 1, usedRange.columnCount - 1);
          targetRange.values = usedRange.values;
  
          // Ensure comments are enabled on the sheet
          if (!snapshot.comments) {
            snapshot.enableComments();
          }
  
          for (let i = 0; i < usedRange.rowCount; i++) {
            for (let j = 0; j < usedRange.columnCount; j++) {
              let formula = usedRange.formulas[i][j];
              if (formula && formula.toString().startsWith('=')) { // Checking specifically for formulas
                const cellAddress = `${String.fromCharCode(65 + j)}${i + 1}`;
                snapshot.comments.add(cellAddress, formula, Excel.ContentType.Plain);
                console.log(`Adding comment to ${cellAddress}: ${formula}`);
              }
            }
          }
  
          await context.sync();
          console.log("Snapshot with formulas as comments created successfully");
        });
      } catch (error) {
        console.error("Error creating snapshot with formulas as comments: ", error);
      }
    }
  </script>
  
  <style>
    .container {
      padding: 1rem;
    }
  </style>
  
  <article class="message is-info">
    <div class="message-header">
      <p>Create Snapshot</p>
    </div>
    <div class="message-body">
      <div class="container">
        <form on:submit|preventDefault={createSnapshot}>
          <div class="field">
            <label class="label" for="snapshotName">Create a copy of the current sheet saving all the current values(results) to a new sheet.</label>
            <div class="control">
              <input
                class="input"
                id="snapshotName"
                type="text"
                bind:value={snapshotName}
                placeholder="Enter snapshot name"
              />
            </div>
          </div>
      
          <div class="field">
            <div class="control">
              <button class="button is-info" type="submit" disabled={!snapshotName}>
                Create Snapshot
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </article>
  